export default {
  path: 'staff',
  component: () => import('@/layouts/staff'), // dashboard
  children: [
    {
      name: 'staff-index',
      path: '',
      component: () => import('@/views/staff/Index')
    },
    {
      name: 'staff-create',
      path: 'create',
      component: () => import('@/views/staff/Create')
    },
    {
      name: 'staff-update',
      path: ':id',
      component: () => import('@/views/staff/Update')
    }
  ]
}
