<template lang="pug">
  div(v-bind="$attrs")
    .d-flex
      .flex-grow-1
        dropdown-select-service(
          ref="dropdown",
          v-model="model",
          :options="dropdownOptions",
          :disabled="disabled",
          :searchable="searchable",
          :clearable="clearable",
          @input="dropdownChange"
        )
</template>
<script>
import utils from '@/libs/utils'
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    },
    searchable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value,
      dropdownOptions: {
        service: this.$services.electionYear.findAll,
        labelKey: 'year',
        valueKey: 'id'
      }
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  methods: {
    onInput (event) {
      let value = event.target.value
      value = utils.convert(value, this.localOption.type)
      this.model = value
      this.$emit('input', value)
    },
    dropdownChange (value) {
      this.model = value
      this.$emit('input', value)
    }
  }
}
</script>
