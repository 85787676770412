export default {
  name: 'สมาชิกที่สมัครเข้ามา',
  form: {
    status: 'สถานะ'
  },
  state: {
    approved: 'อนุมัติ',
    waiting_ect: 'รอการตรวจสอบจาก กกต.',
    waiting_review: 'รอเจ้าหน้าที่พรรคตรวจสอบ',
    rejected: 'ไม่อนุมัติ',
    request_more_document: 'ต้องการเอกสารเพิ่ม'
  },
  personalInformation: {
    header: 'ข้อมูลส่วนตัว',
    fullName: 'ชื่อ - นามสกุล',
    fullNameOld: 'ชื่อ - นามสกุล (เดิม)',
    address: {
      permanentAddress: 'ที่อยู่ตามทะเบียนบ้าน',
      currentAddress: 'ที่อยู่ปัจจุบัน',
      sameAsPermanentAddress: 'เหมือนที่อยู่ตามทะเบียนบ้าน',
      homeNo: 'บ้านเลขที่',
      villageName: 'หมู่บ้าน / อาคาร',
      villageNo: 'หมู่',
      alley: 'ซอย',
      street: 'ถนน',
      subdistrict: 'ตำบล / แขวง',
      district: 'อำเภอ',
      province: 'จังหวัด',
      zipcode: 'รหัสไปรณีย์'
    }
  },
  personalInformationMore: {
    currentMarriedStatus: 'สถานภาพปัจจุบัน',
    currentEducation: 'การศึกษาปัจจุบัน',
    currentOccupation: 'อาชีพปัจจุบัน',
    children: 'จำนวนบุตร',
    coupleFullName: 'ชื่อคู่สมรส',
    highestEducation: 'การศึกษาสูงสุด',
    graduateYear: 'จบเมื่อ',
    educationMajor: 'คณะ / สาขา',
    institute: 'สถาบัน',
    occupationId: 'อาชีพปัจจุบัน',
    occupationPosition: 'ตำแหน่ง',
    officeAddress: 'สถานที่ทำงาน',
    ability: 'ความสามารถพิเศษ'
  },
  idCardInformation: {
    name: 'ข้อมูลเลขบัตรประจำตัวประชาชน',
    idIssueByDistrictId: 'ออกให้ที่ อำเภอ / เขต'
  },
  document: {
    title: 'เอกสารยืนยันตัวตน',
    subTitle: 'อัพโหลดเอกสารต่างๆ ให้ครบถ้วน โดยการแสกน หรือถ่ายภาพให้ชัดเจน และถ่ายภาพเซลฟี่เพื่อยืนยันตัวตน',
    key: {
      idCard: 'สำเนาบัตรประชาชน พร้อมเซ็นรับรองสำเนาถูกต้อง',
      houseRegistration: 'สำเนาทะเบียนบ้าน หน้าแรกและหน้าที่มีชื่อผู้สมัครสมาชิกพรรค ',
      photo: 'รูปถ่ายครึ่งตัว สำหรับทำบัตรสมาชิก',
      nationalityChanged: 'สำเนาใบสำคัญการแปลงสัญชาติเป็นไทย',
      nameChanged: 'สำเนาใบสำคัญเปลี่ยนชื่อ'
    }
  },
  politicalBackground: {
    header: 'ประวัติทางการเมือง',
    electionRegistering: 'การลงสมัครรับเลือกตั้ง',
    questionElection: 'ท่านเคยลงสมัครรับเลือกตั้งในตำแหน่งทางการเมืองหรือไม่?',
    politicalPositionMaintain: 'การดำรงตำแหน่งทางการเมือง',
    questionPoliticalPosition: 'ท่านเคยดำรงตำแหน่งทางการเมืองหรือไม่?',
    position: 'ตำแหน่ง',
    year: 'ปี พ.ศ.',
    addNewPosition: 'คลิก เพื่อเพิ่มข้อมูล',
    moreDetail: 'รายละเอียดเพิ่มเติม',
    proposes: 'วัตถุประสงค์ในการเป็นสมาชิก',
    supports: 'ความต้องการมีส่วนช่วยเหลือพรรค'
  },
  qualificationBackground: {
    header: 'คุณสมบัติ',
    membership: 'คุณสมบัติของสมาชิก',
    question1: 'ท่านเป็น หรือกำลังขอสมัครสมาชิกพรรคการเมืองอื่นหรือไม่',
    question2: 'ท่านเคยเป็นสมาชิกพรรคการเมืองอื่นหรือไม่',
    question3: 'ท่านเคยถูกปฏิเสธคำขอสมัครสมัครสมาชิกพรรคการเมืองอื่นหรือไม่',
    question4: 'ท่านติดยาเสพติดให้โทษหรือไม่',
    question5: 'ท่านเป็นบุคคลล้มละลายหรือเคยเป็นบุคคลล้มละลายทุจริต หรือไม่',
    question6: 'ท่านเป็นภิกษุ สามเณร นักพรต หรือนักบวช หรือไม่',
    question7: 'ท่านอยู่ในระหว่างถูกเพิกถอนสิทธิเลือกตั้ง หรือถูกระงับหรือเพิกถอนการใช้สิทธิสมัครรับเลือกตั้ง หรือไม่',
    question8: 'ท่านเคยได้รับโทษจำคุกโดยได้พ้นโทษมายังไม่ครบสิบปี หรือไม่',
    question9: 'ท่านเคยถูกสั่งให้พ้นจากราชการ หน่วยงานของรัฐ หรือรัฐวิสาหกิจ เพราะทุจริตต่อหน้าที่หรือประพฤติมิชอบในวงราชการหรือไม่',
    question10: 'ท่านเคยต้องคำพิพากษาหรือคำสั่งของศาลอันถึงที่สุดให้ทรัพย์สินตกเป็นของแผ่นดิน หรือไม่',
    question11: 'ท่านเคยต้องคำพิพากษาอันถึงที่สุดว่ากระทำผิดต่อตำแหน่งหน้าที่ราชการ กระทำความผิดตามกฎหมาย ว่าด้วยความผิดของพนักงานในองค์การหรือหน่วยงานรัฐ/ความผิดเกี่ยวกับทรัพย์ที่กระทำโดยทุจริตตามประมวลกฎหมายอาญา กระทำความผิดฐานทุจริตในการเลือกตั้ง กระทำความผิดตามกฎหมายว่าด้วย การกู้ยืมเงินอันเป็นฉ้อโกงประชาชน กฎหมายว่าด้วยยาเสพติดในฐานเป็นผู้ผลิต นำเข้า ส่งออก หรือ เป็นผู้ค้า กฎหมายการพนันในฐานเป็นเจ้ามือเจ้าสำนัก กฎหมายการค้ามนุษย์ หรือกฎหมายปราบปรามการฟอกเงิน หรือไม่',
    question12: 'ท่านเป็นสมาชิกวุฒิสภาหรือเคยเป็นสมาชิกวุฒิสภาและสมาชิกภาพสิ้นสุดลงยังไม่เกินสองปี ใช่หรือไม่',
    question13: 'ท่านเป็นตุลาการศาลรัฐธรรมนูญหรือผู้ดำรงตำแหน่งในองค์กรอิสระ หรือไม่',
    question14: 'ท่านอยู่ในระหว่างต้องห้ามมิให้ดำรงตำแหน่งทางการเมือง หรือไม่',
    question15: 'ท่านเป็นผู้ยื่นคำขอจดทะเบียนจัดตั้งพรรคการเมืองอื่นหรือผู้แจ้งการเตรียมการจัดตั้งพรรคการเมืองอื่นอยู่หรือไม่',
    question16: 'ท่านขาดคุณสมบัติหรือมีลักษณะต้องห้ามอื่นตามที่กฎหมายว่าด้วยพรรคการเมืองกำหนดหรือไม่',
    namePartyInput: 'ชื่อพรรคการเมือง',
    partyDate: ' วัน/ เดือน/ ปี พ.ศ.',
    partyCause: 'สาเหตุ',
    specifyType: 'โปรดระบุประเภท',
    specifyVerdict: 'โปรดแนบสำเนาคำพิพากษาเป็นเอกสารประกอบด้วย'
  },
  agreement: {
    header: 'ค่าบำรุงพรรคการเมือง',
    text: 'ข้าพเจ้าขอยืนยันว่าถ้อยแถลง คำตอบ และเอกสารประกอบคำขอสมัครสมาชิกพรรคเพื่อไทยนี้ เป็นความจริงทุกประการ ข้าพเจ้าเข้าใจดีว่า หากข้าพเจ้าไม่แถลงข้อความจริง หรือปกปิดข้อความที่ควรแจ้งให้พรรคเพื่อไทยทราบ พรรคอาจจะปฏิเสธคำขอนี้หรือเมื่อข้าพเจ้ามีสมาชิกภาพแล้ว พรรคอาจจะเพิกถอนสมาชิกภาพของข้าพเจ้า โดยพรรคไม่ต้องคืนเงินค่าบำรุงพรรคให้แก่ข้าพเจ้า ทั้งนี้ ตามระเบียบที่พรรคกำหนด'
  }
}
