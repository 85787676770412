export default {
  name: 'บัญชีดำ',
  form: {
    fullName: 'ชื่อ - สกุล',
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    status: 'สถานะ',
    type: 'ประเภท',
    expiredAt: 'วันสิ้นสุด',
    createAt: 'สร้างเมื่อ',
    idCard: 'บัตรประจำตัวประชาชน',
    activeStatus: 'สถานะใช้งาน',
    person: 'บุคคล'
  }
}
