<template lang="pug">
  div(v-bind="$attrs")
    .display(v-if="value", )
      .form-control {{ `${query.subdistrict} ${query.district} ${query.province} ${query.zipcode}` }}
      a.delete(@click.prevent="onChange")
        i.fas.fa-times
    suggestions(
      v-else
      v-model='query.subdistrict',
      :options='suggestionsOption',
      :on-input-change='onSubDisTrictInputChange',
      @selected="onSelected")
      .single-item.address(slot='item', slot-scope='props')
        template()
          div
            span(v-html="replaceBoldTag(props.item.subdistrict, query.subdistrict)")
            span.separate &gt;&gt;
            span {{ props.item.district }}
            span.separate &gt;&gt;
            span {{ props.item.province }}
            span.separate &gt;&gt;
            span {{ props.item.zipcode }}
</template>
<script>

export default {
  props: {
    value: {
      type: Number
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const localOptions = Object.assign({
    }, this.options)
    return {
      localOptions,
      suggestionsOption: {
        inputClass: 'form-control',
        placeholder: 'ค้นหาโดยตำบล'
      },
      query: {
        subdistrict: '',
        district: '',
        province: '',
        zipcode: ''
      }
    }
  },
  computed: {
  },
  watch: {
    baseAddressSubdistrictId () {
      if (this.sameAddress === true) {
        this.model.sameAddress = true
      }
    },
    value (value, oldValue) {
      if (value !== oldValue && value) {
        this.displayAddressInput(this.value)
      }
    }
  },
  mounted () {
    if (this.value) {
      this.displayAddressInput(this.value)
    }
  },
  methods: {
    async onSubDisTrictInputChange (query) {
      if (!query || query.length < 3) {
        return []
      }
      const response = await this.$services.location.findAllSubdistrict({
        per_page: 30
      }, query)
      const result = response.map(r => {
        return {
          id: r.id,
          subdistrict: r.name,
          subdistrictId: r.id,
          district: r.district.name,
          province: r.district.province.name,
          provinceId: r.district.province.id,
          zipcode: r.zipcode
        }
      })
      return result
    },
    onSelected (item) {
      if (item.subdistrict) {
        this.query.subdistrict = item.subdistrict
        this.$emit('input', item.id)
      }
      if (item.district) {
        this.query.district = item.district
      }
      if (item.province) {
        this.query.province = item.province
      }
      if (item.zipcode) {
        this.query.zipcode = item.zipcode.toString()
      }
      this.$emit('selected', item)
    },
    replaceBoldTag (text, query) {
      text = text.toString()
      return text.replace(query, `<strong>${query}</strong>`)
    },
    displayAddressInput (id) {
      this.$services.location.findSubdistrict(id)
        .then(row => {
          this.query.subdistrict = row.name
          this.query.district = row.district.name
          this.query.province = row.district.province.name
          this.query.zipcode = row.zipcode.toString()
        })
    },
    onChange () {
      this.query.subdistrict = ''
      this.$emit('input', null)
    }
  }
}
</script>
<style lang="scss" scoped>
.display {
  position: relative;
  .delete {
    $width: 20px;
    position: absolute;
    top: 8px;
    right: 2px;
    width: $width;
    height: $width;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
