export default {
  name: 'กลุ่มของสมาชิก',
  createdAt: 'สร้างเมื่อ',
  status: 'สถานะ',
  expiredNotification: 'การหมดอายุของสมาชิก',
  terminatedNotification: 'การลาออกของสมาชิก',
  form: {
    name: 'ชื่อกลุ่มสมาชิก',
    key: 'คีย์',
    colorPicker: 'สี',
    icon: 'ไอคอน',
    expiredNotification: 'การแจ้งเตือนการหมดอายุของสมาชิก',
    terminatedNotification: 'การแจ้งเตือนการลาออกของสมาชิก',
    provinceId: 'จังหวัด',
    areaNo: 'เขตที่',
    activeStatus: 'สถานะใช้งาน',
    notification: 'การแจ้งเตือน',
    moreInfo: 'ข้อมูลเพิ่มเติม',
    type: 'ประเภท'
  },
  member: {
    memberName: 'สมาชิก',
    name: 'ชื่อ - สกุล',
    idCard: 'บัตรประจำตัวประชาชน',
    expiredAt: 'วันสิ้นสุด',
    groupName: 'ชื่อกลุ่มสมาชิก',
    member: 'สมาชิก',
    provinceId: 'จังหวัด',
    areaNo: 'เขตที่',
    searchMember: 'ค้นหาสมาชิก'
  }
}
