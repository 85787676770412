<template lang="pug">
  div
    .d-flex
      .flex-grow-1
        dropdown-select-service(
          ref="dropdown",
          v-model="model",
          :options="dropdownOptions",
          :disabled="disabled",
          :clearable="clearable"
          :selectable="onSelectable"
          @input="dropdownChange",
          @loaded="onLoaded"
        )
</template>

<script>
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    includeIds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value,
      dropdownOptions: {
        service: this.getService
      }
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  methods: {
    dropdownChange (value) {
      this.model = value
      this.$emit('input', value)
    },
    getService () {
      return this.$services.memberGroup.findAll({
        filter: 'status||eq||true'
      })
        .then((response) => {
          return response
        })
    },
    onLoaded (rows) {
      this.$emit('loaded', rows)
    },
    onSelectable (option) {
      return !this.includeIds.includes(option.value)
    }
  }
}
</script>
