export default {
  moduleName: 'คำขอเปลี่ยนข้อมูลสมาชิก',
  profile: {
    fullName: 'ชื่อ-สกุล',
    idCard: 'เลขบัตรประจำตัวประชาชน',
    source: 'ช่องทางการสมัคร',
    state: 'สถานะ',
    createdAt: 'สร้างเมื่อ',
    updatedAt: 'แก้ไขเมื่อ'
  },
  state: {
    approved: 'อนุมัติ',
    waiting_review: 'รอการตรวจสอบ',
    rejected: 'ไม่ผ่านการตรวจสอบ',
    cancel: 'ยกเลิก'
  },
  review: {
    moduleName: 'ตรวจสอบ',
    general: {
      moduleName: 'ข้อมูล',
      fullName: 'ชื่อ - สกุล',
      tel: 'โทรศัพท์',
      permanentAddress: 'ที่อยู่ตามทะเบียนบ้าน',
      contactAddress: 'ที่อยู่ปัจจุบัน'
    },
    verifyDocument: {
      header: 'เอกสารยืนยันตัวตน',
      subTitle:
        'อัพโหลดเอกสารต่างๆ ให้ครบถ้วน โดยการแสกน หรือถ่ายภาพให้ชัดเจน และถ่ายภาพเซลฟี่เพื่อยืนยันตัวตน',
      idCard: 'สำเนาบัตรประชาชน พร้อมเซ็นรับรองสำเนาถูกต้อง',
      houseRegistration:
        'สำเนาทะเบียนบ้าน หน้าแรกและหน้าที่มีชื่อผู้สมัครสมาชิกพรรค ',
      photo: 'รูปถ่ายครึ่งตัว สำหรับทำบัตรสมาชิก',
      nationalityChanged: 'สำเนาใบสำคัญการแปลงสัญชาติเป็นไทย',
      nameChanged: 'สำเนาใบสำคัญเปลี่ยนชื่อ',
      others: 'เอกสารอื่นๆ โปรดระบุ',
      requiredFileErrorMessage: 'ยังไม่ได้อัพโหลดเอกสาร',
      nameOther: 'ชื่อเอกสาร'
    },
    toMembershipBtn: 'ไปยังสมาชิก'
  },
  onlineChanel: 'ช่องทางออนไลน์'
}
