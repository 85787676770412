export default {
  name: 'ตำบล',
  provinceId: 'จังหวัด',
  districtId: 'อำเภอ',
  form: {
    code: 'รหัสตำบล',
    name: 'ชื่อตำบล',
    district: 'อำเภอ',
    province: 'จังหวัด',
    zipcode: 'รหัสไปรษณีย์',
    createdAt: 'สร้างเมื่อ'
  },
  showSubdistrictWithoutElectionArea: 'แสดงเฉพาะตำบลที่ยังไม่กำหนดเขตเลือกตั้ง',
  createElectionArea: 'กำหนดเขตเลือกตั้ง'
}
