<template lang="pug">
  div
    //- pre {{ value.expiredAt }}
    //- pre {{ localValue }}
    .d-flex
      .flex-grow-1
        fake-input(v-if="!isEdit") {{ expiredDateText }}
        date-selector(v-if="isEdit", v-model="localValue", @input="onLocalValueChange")
      .ml-3.py-1(v-if="!isCreateMode && canEdit")
        a.text-info(v-show="!isEdit", @click="isEdit = true", href="javascript:void(0)") {{ $t('common.edit') }}
        a.text-success(v-show="isEdit", @click="isEdit = false", href="javascript:void(0)") {{ $t('common.done') }}
  //- .row
  //-   .col-6
  //-     label {{ $t ('membership.form.appliedAt') }}
  //-     date-selector(v-model="value.appliedAt")
  //-     small.form-text.error.text-danger(v-show="!$v.appliedAt.required && $v.appliedAt.$dirty") {{ $t ('membership.form.appliedAt') }} ต้องไม่เป็นค่าว่าง
  //-   .col-6
  //-     label {{ $t ('membership.form.expiredAt') }}
</template>
<script>
// import utils from '@/libs/utils'
import { mapGetters } from 'vuex'
import membershipHelper from '@/libs/membershipHelper'
import { mapFilters } from '@/map-filters'
export default {
  props: {
    value: {
      type: Object
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isEdit: false,
      localValue: this.value.expiredAt
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/role'
    }),
    canEdit () {
      return ['admin'].indexOf(this.userRole) !== -1
    },
    isCreateMode () {
      return !this.value.id
    },
    expiredDateText () {
      if (this.isCreateMode) {
        if (this.value.appliedAt) {
          const expiredAt = membershipHelper.calculateMembershipExpireDate(this.value.appliedAt, this.value.membershipType)
          return this.value.membershipType === 'yearly' ? this.bdate(expiredAt) : 'ตลอดชีพ'
        }
        return ''
      } else {
        return this.value.membershipType === 'yearly' ? this.bdate(this.value.expiredAt) : 'ตลอดชีพ'
      }
    }
  },
  methods: {
    ...mapFilters(['bdate']),
    onLocalValueChange (value) {
      if (value) {
        value.setHours(23)
        value.setMinutes(59)
        value.setSeconds(59)
      }
      const model = Object.assign({}, this.value)
      model.expiredAt = value
      this.$emit('input', model)
    }
  }
}
</script>
