<template lang="pug">
  b-modal(ref="modal", :title="title", @ok="save")
    form.register-form
      loading.py-5(v-if="submitting")
      .row(v-else)
        .col-12
          .form-group
            label {{ $t('agent.form.firstName') }}
            input.form-control(data-test="first-name" type='text', v-model='model.firstName')
            small.form-text.text-danger(v-show="!$v.model.firstName.required && $v.model.firstName.$dirty") {{ $t('agent.form.firstName') }} ไม่สามารถเป็นค่าว่าง
          .form-group
            label {{ $t('agent.form.lastName') }}
            input.form-control(data-test="last-name" type='text', v-model='model.lastName')
            small.form-text.text-danger(v-show="!$v.model.lastName.required && $v.model.lastName.$dirty") {{ $t('agent.form.lastName') }} ไม่สามารถเป็นค่าว่าง
          .form-group
            label {{ $t('agent.form.phone') }}
            input.form-control(data-test="phone" type='text', v-model='model.phone')
          .error.text-danger(v-if="error") {{ (error.response) ? error.response.data.message : error.message }}
</template>
<script>
import { required } from 'vuelidate/lib/validators'
// import ProfileService from '@/services/profile'
export default {
  components: {
  },
  validations: {
    model: {
      firstName: { required },
      lastName: { required }
    }
  },
  data () {
    return {
      submitting: false,
      model: {
        name: null
      },
      error: null
    }
  },
  computed: {
    title () {
      let title = this.$t('agent.name')
      if (!this.model.id) {
        title = `${this.$t('common.add')} ${title}`
      } else {
        title = `${this.$t('common.edit')} ${title}`
      }
      return title
    }
  },
  mounted () {
  },
  methods: {
    save (e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitting = true
        this.error = null
        this.$services.agent.save(this.model)
          .then((result) => {
            this.hide()
            this.$emit('save', result)
            setTimeout(() => {
              this.submitting = false
            }, 1000)
          })
          .catch(error => {
            this.submitting = false
            this.error = error
          })
      } else {
        this.$bvToast.toast('ยังกรอกข้อมูลไม่ถูกต้อง', {
          title: 'คำเตือน',
          variant: 'warning',
          solid: true
        })
      }
    },
    show (model) {
      if (model) {
        this.model = Object.assign({}, model)
      } else {
        this.model = {
          firstName: null,
          lastName: null,
          phone: null
        }
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>
<style lang="scss">

</style>
