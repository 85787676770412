<template lang="pug">

  v-select(:reduce="d => d.value",
    v-model="model",
    :options="lists",
    @input="onInput",
    :disabled="disabled"
    :taggable="localOptions.taggable",
    :create-option="tag => ({ label: tag, value: tag })",
    v-bind="$attrs"
    )
    //- select.custom-select(v-model="model", @input="")
    //-   option(selected='selected')
    //-   option(v-for="(value, i) in options.data" :value="i") {{ value }}
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    },
    disabled: {
      default: false
    }
  },
  data () {
    return {
      lists: this.options.data,
      localOptions: Object.assign({
        taggable: false
      }, this.options),
      model: this.value
    }
  },
  computed: {
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  mounted () {
  },
  methods: {
    onInput (item) {
      let value = item

      if (item && typeof item === 'object') {
        value = item.value
      }
      this.$emit('input', value)
      this.$emit('next', this)
    }
  }
}
</script>
