export default (http) => ({
  findAll (query) {
    let params = Object.assign({
      per_page: 100
    }, query)

    return http.get('member-group-member', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  save (data) {
    const payload = this._cleanPayload(data)
    if (payload.id) {
      return this.update(payload)
    } else {
      return this.create(payload)
    }
  },
  create (data) {
    return http.post(`member-group-member`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`member-group-member/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`member-group-member/${id}`)
      .then(response => response.data)
  },
  _cleanPayload (data) {
    const temp = Object.assign({}, data)
    if (temp.member) {
      delete temp.member
    }
    if (temp.memberGroup) {
      delete temp.memberGroup
    }
    return temp
  }
})
