// import moment from 'moment'

export default {
  convert (value, type) {
    if (!type) {
      return value
    }
    if (type === 'Number') {
      const result = parseInt(value)
      if (isNaN(result) || result === null) {
        return null
      }
      return result
    }
    return value
  },
  getAddressFields () {
    return ['addressType', 'homeNo', 'villageNo', 'villageName', 'alley', 'street', 'subdistrictId', 'tel', 'fax', 'mobile', 'sameAddress']
  },
  getPoliticalHistoryFields () {
    return ['politicMembershipRecord', 'positionRecordCurrent', 'positionRecordName', 'positionRecordBelongTo', 'membershipExperiences']
  },
  getDistrictFields () {
    return ['DistrictId', 'ProvinceId']
  },
  getValidationMessage (fieldName, type) {
    switch (type) {
      case 'required': return `${fieldName} ต้องไม่เป็นค่าว่าง`
      case 'isMembership': return `${fieldName} ได้เป็นสมัครสมาชิกแล้ว`
      case 'ageQualify': return `อายุไม่ถึง 18 ปี`
      case 'isNationThai': return `บัตรประชาชนต้องขึ้นต้นด้วย 1 - 5 หรือ 8`
      default: return `${fieldName} ไม่ถูกต้อง`
    }
  },
  generateThaiIdCard () {
    var d1 = Math.floor(Math.random() * 10)
    var d2 = Math.floor(Math.random() * 10)
    var d3 = Math.floor(Math.random() * 10)
    var d4 = Math.floor(Math.random() * 10)
    var d5 = Math.floor(Math.random() * 10)
    var d6 = Math.floor(Math.random() * 10)
    var d7 = Math.floor(Math.random() * 10)
    var d8 = Math.floor(Math.random() * 10)
    var d9 = Math.floor(Math.random() * 10)
    var d10 = Math.floor(Math.random() * 10)
    var d11 = Math.floor(Math.random() * 10)
    var d12 = Math.floor(Math.random() * 10)
    let d13 = null
    var n13 = 11 - (((d1 * 13) + (d2 * 12) + (d3 * 11) + (d4 * 10) + (d5 * 9) + (d6 * 8) + (d7 * 7) + (d8 * 6) + (d9 * 5) + (d10 * 4) + (d11 * 3) + (d12 * 2)) % 11)
    if (n13 === 10) {
      d13 = 0
    } else if (n13 === 11) {
      d13 = 1
    } else {
      d13 = n13
    }
    return `${d1}${d2}${d3}${d4}${d5}${d6}${d7}${d8}${d9}${d10}${d11}${d2}${d13}`
  },
  pad (num, size) {
    var s = num + ''
    while (s.length < size) s = '0' + s
    return s
  },
  subdistrictToString (data) {
    return `${data.name} ${data.district.name} ${data.district.province.name} ${data.zipcode}`
  },
  buildAddressText (address) {
    if (!address) {
      return ''
    }
    const province = address.subdistrict.district.province.name
    const isCapitalCity = province === 'กรุงเทพมหานคร'
    const mooText = 'หมู่'
    const alleyText = 'ซอย'
    const streetText = 'ถนน'
    const districtText = isCapitalCity ? '' : 'อ.'
    const subdistrictText = isCapitalCity ? 'แขวง' : 'ต.'
    const concatStr = (value, prefix, join = '') => {
      const valueNoHasWhitespace = typeof value === 'string' ? value.trim() : value
      if (
        valueNoHasWhitespace === null ||
        valueNoHasWhitespace === undefined ||
        valueNoHasWhitespace === '-'
      ) {
        return null
      }
      if (prefix && valueNoHasWhitespace) {
        return `${prefix}${join}${valueNoHasWhitespace}`
      }
      return valueNoHasWhitespace
    }
    let fields = [
      concatStr(address.homeNo),
      concatStr(address.villageName),
      concatStr(address.villageNo, mooText, ' '),
      concatStr(address.alley, alleyText, ' '),
      concatStr(address.street, streetText, ' '),
      concatStr(address.subdistrict.name, subdistrictText),
      concatStr(address.subdistrict.district.name, districtText),
      concatStr(address.subdistrict.district.province.name),
      concatStr(address.subdistrict.zipcode)
    ]
    fields = fields.filter(f => {
      return f !== null && f !== undefined
    })
    fields = fields.filter(f => {
      return f.trim()
    })
    return fields.join(' ')
  },
  getWindowSize () {
    const width = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth
    const height = window.innerHeight || document.documentElement.clientHeight ||
      document.body.clientHeight

    return { width, height }
  },
  sameAsCurrentUrl (router, location) {
    return router.resolve(location).href === router.currentRoute.fullPath
  },
  getGenderFormTitle (title) {
    const female = [
      'นาง',
      'นางสาว'
    ]
    const male = ['นาย']
    const titles = {}
    female.forEach(title => {
      titles[title] = 'female'
    })
    male.forEach(title => {
      titles[title] = 'male'
    })

    return titles[title] || null
  },
  convertFBDateToDate (date) {
    let value = date
    if (value) {
      if (typeof date.toDate === 'function') {
        value = date.toDate()
      } else if (date._seconds) {
        value = new Date(date._seconds * 1000)
      }
      return value
    }
    return null
  }
}
