<template lang="pug">
  div
    input.form-control(type='text', v-model='model', @input="onInput" ,v-bind="$attrs")
</template>
<script>
import utils from '@/libs/utils'
export default {
  inheritAttrs: false,
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  methods: {
    onInput (event) {
      let value = event.target.value
      value = utils.convert(value, this.localOption.type)
      this.model = value
      this.$emit('input', value)
    }
  }
}
</script>
