// import Vue from 'vue'
// import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import http from '@/libs/http'
import requireContext from 'require-context.macro'

const services = {}

// register components in input folder
let requireComponent = requireContext(
  '../services/', false, /[\w-]\.js$/
)
requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)
  // Get PascalCase name of component
  const componentName = (camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
  // Register component globally
  services[componentName] = componentConfig.default ? componentConfig.default(http) : componentConfig(http)
})
const plugin = {
  install (Vue) {
    Vue.prototype.$services = services
  }
}

export default plugin
