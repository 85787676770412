import Vue from 'vue'
import moment from 'moment'
import { membershipStatus } from '@/data/dropdown/index'
import membershipHelper from '@/libs/membershipHelper'
import { format } from 'friendly-numbers'
import i18n from './langs'

Vue.filter('membershipStatus', function (status) {
  const result = membershipStatus.reduce((result, s) => {
    result[s.value] = s.label
    return result
  }, {})
  return result[status] || status
})
Vue.filter('membershipBadgeStyle', function (status) {
  const submissionStatus = {
    draft: 'badge-outline-secondary',
    review: 'badge-warning',
    resigned: 'badge-secondary',
    rejected: 'badge-danger',
    approved: 'badge-success',
    death: 'badge-secondary',
    expired: 'badge-dark',
    terminated: 'badge-dark'
  }
  return submissionStatus[status] || 'badge-dark'
})

Vue.filter('membershipType', function (status) {
  if (!status) {
    return null
  }
  const type = {
    yearly: 'รายปี',
    permanent: 'ตลอดชีพ'
  }
  return type[status.toLowerCase()]
})

Vue.filter('applicantType', function (status) {
  if (!status) {
    return null
  }
  const type = {
    register: 'สมัครใหม่',
    renew: 'ต่ออายุ'
  }
  return type[status.toLowerCase()]
})

Vue.filter('membershipTypeBadgeStyle', function (status) {
  const type = {
    yearly: 'badge-outline-secondary',
    permanent: 'badge-info'
  }
  return type[status] || 'badge-dark'
})

Vue.filter('paymentStateBadgeStyle', function (state) {
  const states = {
    draft: 'badge-outline-secondary',
    review: 'badge-warning',
    paid: 'badge-secondary',
    approved: 'badge-success',
    rejected: 'badge-secondary'
  }
  return states[state] || 'badge-dark'
})

Vue.filter('expiredBadgeStyle', function (membership) {
  if (membershipHelper.isExpiredMoreThanTwoYears(membership)) {
    return 'badge-danger'
  }
  return 'badge-warning'
})

Vue.filter('expiredStatusText', function (membership) {
  if (membershipHelper.isExpiredMoreThanTwoYears(membership)) {
    return 'หมดอายุถาวร'
  }
  return 'หมดอายุ'
})

Vue.filter('staffStatus', function (status) {
  return status ? 'ใช้งาน' : 'ปิดการใข้งาน'
})

Vue.filter('staffBadgeStyle', function (status) {
  const submissionStatus = {
    inactive: 'badge-danger',
    active: 'badge-success'
  }
  return submissionStatus[status] || 'badge-dark'
})

Vue.filter('waitingMemberBadgeStyle', function (status) {
  const styles = {
    approved: 'badge-success',
    waiting_ect: 'badge-primary',
    waiting_review: 'badge-warning',
    rejected: 'badge-danger',
    request_more_document: 'badge-outline-secondary'
  }
  return styles[status] || 'badge-outline-secondary'
})

Vue.filter('citizenId', function (id) {
  return `${id.slice(0, 1)}-${id.slice(1, 5)}-${id.slice(5, 10)}-${id.slice(10, 12)}-${id.slice(12, 13)}`
})

Vue.filter('strongTag', function (text) {
  return `<strong>${text}</strong>`
})

Vue.filter('verifyStatus', (status) => {
  const verifyStatus = {
    fail: 'ล้มเหลว',
    valid: 'ผ่านการตรวจสอบ',
    invalid: 'ไม่ผ่านการตรวจสอบ',
    waiting: 'รอการตรวจ',
    registered: 'สมัครสมาชิกแล้ว'
  }
  return verifyStatus[status] || ''
})

Vue.filter('verifyBadgeStyle', (status) => {
  const verifyStatus = {
    fail: 'badge-danger',
    valid: 'badge-success',
    invalid: 'badge-warning',
    waiting: 'badge-info',
    registered: 'badge-secondary'
  }
  return verifyStatus[status] || ''
})

Vue.filter('fulldate', (date) => {
  return date ? moment(date).format('DD-MM-YYYY HH:mm:ss') : null
})
Vue.filter('bfulldate', (date) => {
  const year = parseInt(moment(date).format('YYYY')) + 543
  const day = date ? moment(date).format('DD-MM-') + year : null
  const time = moment(date).format('HH:mm:ss')
  return `${day} ${time}`
})
Vue.filter('date', (date) => {
  return date ? moment(date).format('DD-MM-YYYY') : null
})
Vue.filter('bdate', (date) => {
  const year = parseInt(moment(date).format('YYYY')) + 543
  return date ? moment(date).locale('th').format('D MMM ') + year : null
})

Vue.filter('byear', (year) => {
  return year + 543
})

Vue.filter('notification', (notification) => {
  return notification < 100 ? notification : '99+'
})

Vue.filter('mobile', (mobile) => {
  if (mobile) {
    return `(${mobile.slice(0, 3)}) ${mobile.slice(3, 5)} ${mobile.slice(5, 8)} ${mobile.slice(8, 12)}`
  }
  return null
})

Vue.filter('mobileDashed', (mobile) => {
  if (mobile) {
    return `${mobile.slice(0, 3)}-${mobile.slice(3, 6)}-${mobile.slice(6, 10)}`
  }
  return null
})

Vue.filter('numberWithCommas', (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('ectBadgeStyle', (status) => {
  const ectStatus = {
    succeed: 'badge-success',
    waiting: 'badge-outline-warning',
    queued: 'badge-warning',
    failed: 'badge-danger',
    error: 'badge-danger',
    not_ready: 'badge-outline-secondary'
  }
  return ectStatus[status] || ''
})

Vue.filter('friendlyNumber', (number) => {
  return format(number)
})

/** Agent and payment filter **/
Vue.filter('agentPaidStatus', (status) => {
  return status ? 'ชำระเงินแล้ว' : 'รอการชำระ'
})

Vue.filter('agentPaymentMethod', (method) => {
  const paymentMethod = {
    QR: 'Thai QR Payment',
    CASH: 'เงินสด'
  }
  return paymentMethod[method] || ''
})

/** Applicant State Filter **/
Vue.filter('applicantState', (state) => {
  return i18n.te(`onlineChannel.applicant.states.${state}`) ? i18n.t(`onlineChannel.applicant.states.${state}`) : status
})

Vue.filter('mobileAgentApplicantState', (state) => {
  return i18n.te(`mobileAgent.applicant.states.${state}`) ? i18n.t(`mobileAgent.applicant.states.${state}`) : status
})

Vue.filter('applicantBadgeStyle', (state) => {
  const applicantState = {
    pending: 'badge-outline-warning',
    rejected: 'badge-danger',
    approved: 'badge-success',
    request_document: 'badge-warning',
    refund: 'badge-outline-dark',
    cancel: 'badge-outline-dark'
  }
  return applicantState[state] || ''
})

Vue.filter('onlineChannelPayment', (state) => {
  const applicantState = {
    pending: 'รอการชำระ',
    completed: 'ชำระแล้ว',
    expired: 'หมดเวลาชำระ',
    canceled: 'ยกเลิก',
    failed: 'ไม่สำเร็จ',
    refund: 'คืนเงินแล้ว',
    request_refund: 'รอการคืนเงิน'
  }
  return applicantState[state] || state
})

Vue.filter('onlineChannelPaymentBadgeStyle', function (status) {
  const submissionStatus = {
    pending: 'badge-warning',
    completed: 'badge-success',
    expired: 'badge-outline-secondary',
    canceled: 'badge-outline-dark',
    failed: 'badge-danger',
    request_refund: 'badge-outline-warning'
  }
  return submissionStatus[status] || 'badge-dark'
})

// firestore date type
Vue.filter('fbFullDate', (date) => {
  let value = date
  if (value) {
    if (typeof date.toDate === 'function') {
      value = date.toDate()
    } else if (date._seconds) {
      value = new Date(date._seconds * 1000)
    }
    const year = parseInt(moment(value).format('YYYY')) + 543
    return value ? moment(value).locale('th').format('D MMM ') + year : null
  }
  return null
})

Vue.filter('profileChangeRequestBadgeStyle', (state) => {
  const profileChangeRequestState = {
    approved: 'badge-success',
    waiting_review: 'badge-warning',
    rejected: 'badge-danger',
    cancel: 'badge-dark'
  }
  return profileChangeRequestState[state] || 'badge-dark'
})

// filter membership status print
Vue.filter('memberShipCardPrintBadgeStyle', function (status) {
  const statusPrint = {
    printed: 'badge-success',
    unprinted: 'badge-danger',
    requested: 'badge-warning'
  }
  return statusPrint[status] || 'badge-dark'
})

Vue.prototype.$filters = Vue.options.filters
