import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
// import compactSidebar from './modules/compactSidebar'
import membership from './modules/membership'
import config from './modules/config'
import auth from './modules/auth'
import notification from './modules/notification'

// import invoice from './modules/invoice'
// import cart from './modules/cart'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    // compactSidebar,
    config,
    membership,
    notification,
    // invoice,
    // cart,
    auth
  }
})
