import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// register components in base folder
let requireComponent = require.context(
  './components/base', false, /[\w-]\.vue$/
)
requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)
  // Get PascalCase name of component
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig)
})

// register components in input folder
requireComponent = require.context(
  './components/input', false, /[\w-]\.vue$/
)
requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)
  // Get PascalCase name of component
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig)
})
