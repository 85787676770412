export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: ['name,ASC']
    }, query)
    if (params.search) {
      const q = params.search
      params.or = [`name||cont||${q}`]
      delete params.search
    }
    return http.get('titleName', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`titleName/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`titleName`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`titleName/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`titleName/${id}`)
      .then(response => response.data)
  }
})
