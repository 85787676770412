export default {
  name: 'รายงานการชำระเงิน',
  index: 'รายการชำระทั้งหมด',
  table: {
    firstName: 'ชื่อ',
    lastName: 'นามสกุล',
    membershipType: 'ประเภทสมาชิก',
    amount: 'จำนวนเงิน',
    bankRef: 'เลขอ้างอิงธนาคาร',
    paymentState: 'สถานะ',
    createdAt: 'สร้างเมื่อ',
    completedAt: 'เสร็จสิ้นเมื่อ'
  }

}
