<template lang="pug">
  .form-row(:name="name")
    .col-12
      select.custom-select.mr-sm-2(v-model='model', @input="onChange")
        option(:value='null') เลือก
        option(v-for='y in years' :key='y', :value='y') {{ y + 543 }}
    //- .col-12
    //-   | {{ currentBuddhistYear }}
</template>
<script>
import moment from 'moment'

export default {
  inheritAttrs: false,
  props: {
    $v: {
      type: Object
    },
    value: {
      // type: Date,
      default: null
    },
    options: {
      type: Object
    },
    name: {}
  },
  data () {
    const localOption = Object.assign({
      startYear: parseInt(moment().format('YYYY')) - 50,
      endYear: parseInt(moment().format('YYYY'))
    }, this.options)
    return {
      model: this.value,
      localOption
    }
  },
  computed: {
    years () {
      const result = []
      for (let i = this.localOption.endYear; i >= this.localOption.startYear; i = i - 1) {
        result.push(parseInt(i))
      }
      return result
    }
  },
  watch: {
    value (value, oldValue) {
      this.model = value
    }
  },
  mounted () {
  },
  methods: {
    onChange (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
