<template lang="pug">
  .display()
    .form-control
      slot
    a.delete(v-if="showDelete", @click.prevent="$emit('delete')")
      i.fas.fa-times
</template>
<script>
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    showDelete: {
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.display {
  position: relative;
  .delete {
    $width: 20px;
    position: absolute;
    top: 8px;
    right: 2px;
    width: $width;
    height: $width;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
