export default {
  name: 'Agent App',
  account: {
    moduleName: 'บัญชีตัวแทนของแอพ',
    email: 'อีเมล',
    uid: 'uid',
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    branch: 'สาขา',
    electionArea: 'เขต',
    provinceId: 'จังหวัด',
    status: 'สถานะ',
    createdAt: 'สร้างเมื่อ',
    states: {
      true: 'ใช้งาน',
      false: 'ปิดการใข้งาน'
    }
  },
  applicant: {
    moduleName: 'ข้อมูลผู้สมัคร',
    email: 'อีเมล',
    uid: 'uid',
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    idCard: 'เลขบัตรประจำตัวประชาชน',
    membershipType: 'รูปแบบสมาชิก',
    paymentMethod: 'รูปแบบการชำระ',
    paymentAmount: 'ยอดชำระ',
    paymentReceipt: 'ใบเสร็จรับเงินชั่วคราวเลขที่',
    paymentRef: 'Merchant Ref.',
    iPayRef: 'iPay Ref.',
    isPaid: 'สถานะการจ่ายเงิน',
    state: 'สถานะ',
    createdAt: 'สร้างเมื่อ',
    paidAt: 'ชำระเมื่อ',
    refundAt: 'วันที่คืนเงิน',
    subscriptionPrice: 'ราคา',
    form: {
      title: 'คำนำหน้า',
      name: 'ชื่อ',
      fullName: 'ชื่อ-สกุล',
      firstName: 'ชื่อ',
      lastName: 'สกุล',
      birthDate: 'วันเกิด',
      state: 'สถานะ',
      nationality: 'สัญชาติ',
      race: 'เชื้อชาติ',
      religion: 'ศาสนา',
      idCard: 'บัตรประจำตัวประชาชน',
      idCardShort: 'id card',
      partyId: 'รหัสสมาชิก',
      idIssueDate: 'วันออกบัตร',
      idExpireDate: 'วันหมดอายุ',
      marriedStatus: 'สถานภาพ',
      birthProvinceId: 'จังหวัดที่เกิด',
      registerPlace: 'สถานที่ยื่นใบสมัคร',
      membershipType: 'ระยะเวลาสมาชิก',
      address: 'ที่อยู่ตามทะเบียนบ้าน',
      idPhoto: 'รูปผู้สมัคร',
      signature: 'ลายเซ็นต์ผู้สมัคร',
      agentId: 'ผู้หาสมาชิก',
      appliedAt: 'รับเป็นสมาชิก',
      receivedAt: 'ยื่นใบสมัคร',
      receivedAndAppliedAt: 'ยื่นใบสมัคร / รับเป็นสมาชิก',
      expiredAt: 'วันที่หมดอายุการเป็นสมาชิก',
      bookNo: 'เล่มที่',
      receiptNo: 'เลขที่',
      receiptDate: 'วันที่',
      amount: 'จำนวนเงิน',
      endDate: 'สิ้นสุด',
      successCreatedTitle: 'บันทึกข้อมูลสมาชิกเรียบร้อย',
      sendToReview: 'ส่งไปตรวจ',
      sendToDraft: 'ส่งไปยังแบบร่างสมาชิก',
      approved: 'อนุมัติ',
      backToDraft: 'กลับสู่แบบร่าง',
      reject: 'ไม่ผ่านการตรวจสอบ ทำเรื่องคินเงิน',
      requestDocs: 'ขอเอกสารเพิ่มเติม',
      resigned: 'ลาออก',
      terminated: 'ยุติการเป็นสมาชิก',
      death: 'เสียชีวิต',
      resignedAt: 'ออกเมื่อวันที่',
      printedAt: 'พิมพ์เมื่อวันที่',
      rejectReason: 'แจ้งเตือน',
      newRegister: 'สมัครใหม่',
      useThisData: 'ใช้ข้อมูลนี้',
      memberAt: 'เป็นสมาชิก',
      gender: 'เพศ',
      sendToCreateReceipt: 'ส่งไปออกใบเสร็จ',
      markRefund: 'บันทึกว่าคืนเงินแล้ว',
      toMembership: 'ไปยังสมาชิก'
    },
    address: {
      homeNo: 'บ้านเลขที่',
      villageName: 'หมู่บ้าน / อาคาร',
      villageNo: 'หมู่ที่',
      alley: 'ตรอก / ซอย',
      street: 'ถนน',
      subdistrict: 'ตำบล / แขวง',
      district: 'อำเภอ / เขต',
      province: 'จังหวัด',
      zipcode: 'รหัสไปรษณีย์',
      tel: 'โทรศัพท์',
      fax: 'โทรสาร',
      mobile: 'มือถือ',
      email: 'อีเมล'
    },
    deleteDialog: {
      title: 'ยืนยันการลบข้อมูลผู้สมัคร',
      message: 'หากคุณต้องการลบข้อมูลผู้สมัครท่านนี้ จำนวนเงินจะถูกเปลี่ยนแปลงจาก {old} บาท เป็น {new} บาท'
    },
    updateDialog: {
      title: 'แก้ไขข้อมูลผู้สมัคร'
    },
    states: {
      pending: 'รอการตรวจสอบ',
      rejected: 'รอการคืนเงิน',
      request_document: 'ขอเอกสารเพิ่ม',
      approved: 'อนุมัติ',
      refund: 'คืนเงินแล้ว',
      cancel: 'ยกเลิก'
    }
  },
  transferPayment: {
    moduleName: 'รายงานการโอนเงิน',
    create: 'สร้างการรับโอนเงินใหม่',
    view: 'การรับโอนเงิน',
    agentName: 'ชื่อตัวแทน',
    amount: 'จำนวนเงิน',
    paymentTime: 'โอนเมื่อ',
    createdAt: 'สร้างเมื่อ'
  },
  requestRefund: {
    moduleName: 'รายการคืนเงิน'
  },
  error: {
    'This agent already exists': 'มีชื่อนี้อยู่ในระบบแล้ว'
  }
}
