export default {
  name: 'เขตเลือกตั้ง',
  provinceId: 'จังหวัด',
  form: {
    electionYear: 'ปีการเลือกตั้ง',
    areaNo: 'เขตเลือกตั้ง',
    subdistrict: 'ตำบล',
    district: 'อำเภอ',
    province: 'จังหวัด',
    zipcode: 'รหัสไปรษณีย์',
    createdAt: 'สร้างเมื่อ'
  }
}
