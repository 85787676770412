export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)
    return http.get('location/district', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`location/district/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`location/district`, data)
      .then(response => response.data)
  },
  update (data) {
    const putdata = this._clean(data)
    return http.put(`location/district/${putdata.id}`, putdata)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`location/district/${id}`)
      .then(response => response.data)
  },
  _clean (data) {
    const result = Object.assign({}, data)
    if (result.province) {
      delete result.province
    }
    return result
  }
})
