<template lang="pug">
  div
    .d-flex
      .flex-grow-1
        dropdown-select-service(ref="dropdown", v-model="model", :options="dropdownOptions", @input="dropdownChange")
      .ml-2
        button.btn-sm.btn.btn-primary(type="button", @click.prevent="addNew")
          i.fas.fa-plus
    v-form(ref="modal", @save="onSave")
</template>
<script>
import utils from '@/libs/utils'
import Form from '@/views/educationLevel/Form'
// import { education } from '@/data/dropdown'
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  components: {
    VForm: Form
  },
  data () {
    const localOption = Object.assign({
      type: 'String',
      valueKey: 'name'
    }, this.options)
    return {
      localOption,
      model: this.parseInput(this.value),
      dropdownOptions: {
        service: () => {
          return this.$services.educationLevel.findAll({
            sort: 'name,ASC'
          })
        },
        valueKey: localOption.valueKey
      }
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  methods: {
    parseInput (value) {
      return value
    },
    onInput (event) {
      let value = event.target.value
      value = utils.convert(value, this.localOption.type)
      this.model = value
      this.$emit('input', value)
    },
    addNew () {
      this.$refs.modal.show()
    },
    onSave (model) {
      this.$emit('input', model.id)
      this.$refs.dropdown.loadData()
    },
    dropdownChange (value) {
      this.model = value
      this.$emit('input', value)
    }
  }
}
</script>
