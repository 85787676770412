
import Vue from 'vue'
const state = {
  count: {
    membership: 0
  },
  countWorker: {}
}
const getters = {

}
const mutations = {
  setNotificationCount (state, { module, value }) {
    state.count[module] = value
  }
}

const actions = {
  setNotification ({ commit }, { key, value }) {
    commit('setNotificationCount', { module, value })
  },
  startNotificationWorker ({ commit, dispatch, rootGetters }) {
    const role = rootGetters['auth/role']
    if (role === 'accounting') {
      return
    }
    const task = () => {
      dispatch('fetchMembershipCount')
    }
    task()
    state.countWorker.membership = setInterval(task, 60000)
  },
  fetchMembershipCount ({ commit, rootGetters }) {
    const role = rootGetters['auth/role']
    const staffId = rootGetters['auth/id']
    const params = {}
    const filter = []
    if (role === 'admin') {
      params.join = ['paymentReceipts']
      params.or = ['state||eq||review', 'paymentReceipts.state||eq||draft', 'paymentReceipts.state||eq||review', 'paymentReceipts.state||eq||paid']
    } else if (['staff', 'supervisor'].indexOf(role) !== -1) {
      filter.push(`state||eq||draft`)
      filter.push(`rejectReason||notnull`)
      filter.push(`createdBy||eq||${staffId}`)
    }
    params.filter = filter

    if (params.filter.length > 0) {
      Vue.prototype.$services.membership.count(params)
        .then(data => {
          commit('setNotificationCount', { module: 'membership', value: data.total })
        })
    }
  },
  stopNotificationWorker ({ commit, rootState }) {
    if (state.countWorker.membership) {
      clearInterval(state.countWorker.membership)
      state.countWorker.membership = null
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
