export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,ASC'
    }, query)
    if (params.search) {
      const q = params.search
      params.keyword = q
      delete params.search
    }
    return http.get('mobile-agent-applicant', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({}, query)
    return http.get(`mobile-agent-applicant/${id}`, { params })
      .then(response => response.data)
  },
  update (id, model) {
    return http.put(`mobile-agent-applicant/${id}`, model)
      .then(response => response.data)
  }
})
