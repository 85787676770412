export default (http) => ({
  save (document) {
    if (document.id) {
      return this.update(document)
    } else {
      return this.create(document)
    }
  },
  create (data) {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })
    return http.post(`membership-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  update (data) {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })
    return http.put(`membership-document/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  delete (id) {
    return http.delete(`membership-document/${id}`)
      .then(response => response.data)
  },
  updateOnlineChannelDocuments (membershipId) {
    const params = { membershipId }
    return http.get('membership-document/update-online-channel-documents', { params })
      .then(response => response)
  }
})
