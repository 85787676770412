<template lang="pug">
modal-wrapper(size="modal-md", ref="modal")
  .modal-body
    div {{ message }}
  .modal-footer
    button.btn.btn-primary(@click="onOkClick", :class="{ 'is-loading': loading }") {{ okText }}
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'AlertDialog',
  data () {
    return {
      loading: false,
      message: 'test message',
      okText: this.$t('common.ok'),
      onOk: () => {}
    }
  },
  methods: {
    show ({ message, onOk, okText }) {
      this.message = message
      if (okText) {
        this.okText = okText
      }
      this.onOk = onOk
      this.$refs.modal.show()
    },
    hide () {
      if (this.loading) {
        return
      }
      this.$refs.modal.hide()
    },
    onOkClick () {
      if (this.onOk) {
        this.onOk()
      }
      this.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
.modal-footer {
  border: none;
}
</style>
