export default {
  name: 'รายงาน',
  form: {
    choose: 'เลือกรายงาน',
    condition: 'เงื่อนไข',
    startAt: 'เริ่มวันที่',
    endAt: 'ถึงวันที่',
    areaNo: 'เขตเลือกตั้ง',
    provinceId: 'จังหวัด',
    no: 'ช่วงที่',
    year: 'ปี',
    download: 'ดาวน์โหลดเอกสาร',
    email: 'อีเมล',
    receiverEmail: 'ระบุอีเมล',
    source: 'ช่องทางการสมัคร',
    receiptType: 'รูปแบบการสมัคร',
    membershipType: 'รูปแบบสมาชิก',
    electionYearId: 'ปีการเลือกตั้ง'
  }
}
