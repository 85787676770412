export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)

    if (query.search) {
      params.keyword = query.search
      delete params.search
    }

    return http.get('person', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)

    return http.get(`person/${id}`, { params })
      .then(response => response.data)
  },
  create (data) {
    return http.post(`person`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`person/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`person/${id}`)
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  bulk (models) {
    const data = {
      bulk: models
    }
    return http.post(`person/bulk`, data)
      .then(response => response.data)
  }
})
