<template lang="pug">
  .form-row(:name="name" v-bind="$attrs")
    //- .col-12 1. - {{ date }} {{ month }} {{ year }}
    //- .col-12 2. - {{ model }}
    //- .col-12 3. - {{ value }}
    .col-4
      select.mr-sm-2.custom-select(v-model='date' :disabled="disabled")
        option(:value='null') เลือก
        option(v-for='d in 31' :key='d' :value='d') {{d}}
    .col-4
      select.custom-select.mr-sm-2(v-model='month' :disabled="disabled")
        option(:value='null') เลือก
        option(v-for='(m, i) in MONTHS' :key='i' :value='(i + 1)') {{m}}
    .col-4
      select.custom-select.mr-sm-2(v-model='year' :disabled="disabled")
        option(:value='null') เลือก
        option(v-for='y in years' :key='y', :value='y') {{y}}
    //- .col-12
    //-   | {{ currentBuddhistYear }}
</template>
<script>
import moment from 'moment'

export default {
  inheritAttrs: false,
  props: {
    $v: {
      type: Object
    },
    value: {
      // type: Date,
      default: null
    },
    options: {
      type: Object
    },
    name: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    // const dateObject = moment(date)
    const localOption = Object.assign({
      type: 'datetime',
      startYear: parseInt(moment().format('YYYY')) + 15
    }, this.options)
    return {
      model: null,
      date: null,
      month: null,
      year: null,
      // date: dateObject.date(),
      // month: dateObject.month() + 1,
      // year: dateObject.year() + 543,
      // dateObject,
      MONTHS: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
      ],
      localOption
    }
  },
  computed: {
    currentBuddhistYear () {
      return this.localOption.startYear + 543
    },
    years () {
      const result = []
      for (let i = this.currentBuddhistYear; i > 2400; i = i - 1) {
        result.push(i)
      }
      return result
    }
  },
  watch: {
    date (newValue) {
      this.saveOutput()
    },
    month (newValue) {
      this.saveOutput()
    },
    year (newValue) {
      this.saveOutput()
    },
    value (value, oldValue) {
      if (value === null) {
        this.date = null
        this.month = null
        this.year = null
      } else {
        if (value !== oldValue) {
          this.parseInput()
        }
      }
    }
  },
  mounted () {
    this.parseInput()
  },
  methods: {
    parseInput () {
      if (this.value) {
        if (this.localOption.type === 'datetime') {
          const model = moment(this.value)
          this.date = model.date()
          this.month = model.month() + 1
          this.year = model.year() + 543
        } else if (this.localOption.type === 'date') {
          const [year, month, date] = this.value.split('-')
          this.date = parseInt(date)
          this.month = parseInt(month)
          this.year = parseInt(year) + 543
        }
      }
    },
    saveOutput () {
      if (this.date && this.month && this.year) {
        if (this.localOption.type === 'datetime') {
          const date = new Date(this.year - 543, this.month - 1, this.date)
          this.model = date
        } else if (this.localOption.type === 'date') {
          const value = `${this.year - 543}-${this.month}-${this.date}`
          this.model = value
        }
        this.$emit('input', this.model)
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>
