export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: ['firstName,ASC', 'lastName,ASC']
    }, query)
    if (params.search) {
      const q = params.search
      params.or = [`firstName||cont||${q}`, `lastName||cont||${q}`]
      delete params.search
    }
    return http.get('mobile-agent-account', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`mobile-agent-account/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`mobile-agent-account`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`mobile-agent-account/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`mobile-agent-account/${id}`)
      .then(response => response.data)
  }
})
