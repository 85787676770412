const baseName = 'request-refund'

export default {
  path: `/${baseName}`,
  component: () => import('@/layouts/auth'),
  children: [
    {
      path: 'mobile-agent',
      name: `${baseName}-mobile-agent-index`,
      component: () => import('@/views/requestRefund/mobileAgent/Index')
    },
    {
      path: 'online-channel',
      name: `${baseName}-online-channel-index`,
      component: () => import('@/views/requestRefund/onlineChannel/Index')
    }
  ]
}
