export default {
  name: 'ช่องทางออนไลน์',
  applicant: {
    moduleName: 'ข้อมูลผู้สมัคร',
    email: 'อีเมล',
    uid: 'uid',
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    idCard: 'เลขบัตรประจำตัวประชาชน',
    membershipType: 'รูปแบบสมาชิก',
    applicantType: 'ชนิดใบสมัคร',
    paymentMethod: 'รูปแบบการชำระ',
    paymentAmount: 'ยอดชำระ',
    paymentReceipt: 'ใบเสร็จรับเงินเลขที่',
    isPaid: 'สถานะการชำระ',
    state: 'สถานะ',
    createdAt: 'สร้างเมื่อ',
    appliedAt: 'สมัครเมื่อ',
    paidAt: 'ชำระเมื่อ',
    expiredAt: 'หมดอายุวันที่',
    form: {
      email: 'อีเมล',
      mobile: 'โทรศัพท์',
      fullName: 'ชื่อ-สกุล',
      oldFullName: 'ชื่อ - นามสกุลเดิม',
      birthDate: 'วันเกิด (วัน / เดือน / พ.ศ.)',
      gender: 'เพศ',
      idCard: 'หมายเลขบัตรประชาชน (13 หลัก)',
      nationality: 'สัญชาติ',
      race: 'เชื้อชาติ',
      permanentAddress: 'ที่อยู่ตามทะเบียนบ้าน',
      currentAddress: 'ที่อยู่ปัจจุบัน',
      reject: 'ไม่ผ่านการตรวจสอบ',
      sendToDraft: 'ส่งไปยังแบบร่างสมาชิก',
      requestDocs: 'ขอเอกสารเพิ่มเติม',
      markRefund: 'บันทึกว่าคืนเงินแล้ว',
      confirmAndSendToCreatePaymentReceipt: 'ยืนยันและออกใบเสร็จ',
      sendToPending: 'ส่งไปตรวจ',
      toMembership: 'ไปยังสมาชิก'
    },
    verifyDocument: {
      header: 'เอกสารยืนยันตัวตน',
      subTitle:
        'อัพโหลดเอกสารต่างๆ ให้ครบถ้วน โดยการแสกน หรือถ่ายภาพให้ชัดเจน และถ่ายภาพเซลฟี่เพื่อยืนยันตัวตน',
      idCard: 'สำเนาบัตรประชาชน พร้อมเซ็นรับรองสำเนาถูกต้อง',
      houseRegistration:
        'สำเนาทะเบียนบ้าน หน้าแรกและหน้าที่มีชื่อผู้สมัครสมาชิกพรรค ',
      photo: 'รูปถ่ายครึ่งตัว สำหรับทำบัตรสมาชิก',
      nationalityChanged: 'สำเนาใบสำคัญการแปลงสัญชาติเป็นไทย',
      nameChanged: 'สำเนาใบสำคัญเปลี่ยนชื่อ',
      others: 'เอกสารอื่นๆ โปรดระบุ',
      requiredFileErrorMessage: 'ยังไม่ได้อัพโหลดเอกสาร',
      nameOther: 'ชื่อเอกสาร'
    },
    politicalBackground: {
      header: 'ประวัติทางการเมือง',
      electionRegistering: 'การลงสมัครรับเลือกตั้ง',
      questionElection:
        'ท่านเคยลงสมัครรับเลือกตั้งในตำแหน่งทางการเมืองหรือไม่?',
      politicalPositionMaintain: 'การดำรงตำแหน่งทางการเมือง',
      questionPoliticalPosition: 'ท่านเคยดำรงตำแหน่งทางการเมืองหรือไม่?',
      position: 'ตำแหน่ง',
      year: 'ปี พ.ศ.',
      addNewPosition: 'คลิก เพื่อเพิ่มข้อมูล',
      politicPartyExperiences: 'ประสบการณ์ทางการเมือง',
      partyName: 'เคยสังกัดพรรค',
      province: 'ประจำจังหวัด',
      duration: 'ระยะเวลาในการดำรงตำแหน่ง',
      workExperiences: 'ประสบการณ์การทำงาน',
      place: 'สถานที่ทำงาน',
      startDate: 'วันที่เรื่มงาน'
    },
    qualificationBackground: {
      header: 'คุณสมบัติ',
      membership: 'คุณสมบัติของสมาชิก',
      question1: '1. ท่านเป็น หรือกำลังขอสมัครสมาชิกพรรคการเมืองอื่นหรือไม่',
      question2: '2. ท่านเคยเป็นสมาชิกพรรคการเมืองอื่นหรือไม่',
      question3:
        '3. ท่านเคยถูกปฏิเสธคำขอสมัครสมัครสมาชิกพรรคการเมืองอื่นหรือไม่',
      question4: '4. ท่านติดยาเสพติดให้โทษหรือไม่',
      question5:
        '5. ท่านเป็นบุคคลล้มละลายหรือเคยเป็นบุคคลล้มละลายทุจริต หรือไม่',
      question6: '6. ท่านเป็นภิกษุ สามเณร นักพรต หรือนักบวช หรือไม่',
      question7:
        '7. ท่านอยู่ในระหว่างถูกเพิกถอนสิทธิเลือกตั้ง หรือถูกระงับหรือเพิกถอนการใช้สิทธิสมัครรับเลือกตั้ง หรือไม่',
      question8: '8. ท่านเคยได้รับโทษจำคุกโดยได้พ้นโทษมายังไม่ครบสิบปี หรือไม่',
      question9:
        '9. ท่านเคยถูกสั่งให้พ้นจากราชการ หน่วยงานของรัฐ หรือรัฐวิสาหกิจ เพราะทุจริตต่อหน้าที่หรือประพฤติมิชอบในวงราชการหรือไม่',
      question10:
        '10. ท่านเคยต้องคำพิพากษาหรือคำสั่งของศาลอันถึงที่สุดให้ทรัพย์สินตกเป็นของแผ่นดิน หรือไม่',
      question11:
        '11. ท่านเคยต้องคำพิพากษาอันถึงที่สุดว่ากระทำผิดต่อตำแหน่งหน้าที่ราชการ กระทำความผิดตามกฎหมาย ว่าด้วยความผิดของพนักงานในองค์การหรือหน่วยงานรัฐ/ความผิดเกี่ยวกับทรัพย์ที่กระทำโดยทุจริตตามประมวลกฎหมายอาญา กระทำความผิดฐานทุจริตในการเลือกตั้ง กระทำความผิดตามกฎหมายว่าด้วย การกู้ยืมเงินอันเป็นฉ้อโกงประชาชน กฎหมายว่าด้วยยาเสพติดในฐานเป็นผู้ผลิต นำเข้า ส่งออก หรือ เป็นผู้ค้า กฎหมายการพนันในฐานเป็นเจ้ามือเจ้าสำนัก กฎหมายการค้ามนุษย์ หรือกฎหมายปราบปรามการฟอกเงิน หรือไม่',
      question12:
        '12. ท่านเป็นสมาชิกวุฒิสภาหรือเคยเป็นสมาชิกวุฒิสภาและสมาชิกภาพสิ้นสุดลงยังไม่เกินสองปี ใช่หรือไม่',
      question13:
        '13. ท่านเป็นตุลาการศาลรัฐธรรมนูญหรือผู้ดำรงตำแหน่งในองค์กรอิสระ หรือไม่',
      question14:
        '14. ท่านอยู่ในระหว่างต้องห้ามมิให้ดำรงตำแหน่งทางการเมือง หรือไม่',
      question15:
        '15. ท่านเป็นผู้ยื่นคำขอจดทะเบียนจัดตั้งพรรคการเมืองอื่นหรือผู้แจ้งการเตรียมการจัดตั้งพรรคการเมืองอื่นอยู่หรือไม่',
      question16:
        '16. ท่านขาดคุณสมบัติหรือมีลักษณะต้องห้ามอื่นตามที่กฎหมายว่าด้วยพรรคการเมืองกำหนดหรือไม่',
      namePartyInput: 'ชื่อพรรคการเมือง',
      partyDate: ' วัน/ เดือน/ ปี พ.ศ.',
      partyCause: 'สาเหตุ',
      specifyType: 'โปรดระบุประเภท',
      specifyVerdict: 'โปรดแนบสำเนาคำพิพากษาเป็นเอกสารประกอบด้วย'
    },
    states: {
      pending: 'รอการตรวจสอบ',
      rejected: 'ไม่ผ่านการตรวจสอบ',
      request_document: 'ขอเอกสารเพิ่ม',
      approved: 'อนุมัติ',
      refund: 'คืนเงิน',
      cancel: 'ยกเลิก'
    },
    renewInformation: {
      header: 'ข้อมูลการต่ออายุสมาชิก',
      payment: 'ข้อมูลการต่ออายุสมาชิกพรรค',
      price: 'ราคา'
    },
    renewConfirmDialog: {
      title: 'อนุมัติการต่ออายุสมาชิก',
      message: 'เจ้าหน้าที่ได้ตรวจสอบข้อมูลการต่ออายุเรียบร้อยแล้ว'
    }
  },
  payment: {
    moduleName: 'รายงานการชำระเงิน',
    name: 'ชื่อ สกุล',
    idCard: 'เลขบัตรประจำตัวประชาชน',
    status: 'สถานะการจ่ายเงิน',
    amount: 'จำนวนเงิน',
    paymentTime: 'ชำระเมื่อ',
    orderId: 'Merchant Ref.',
    createdAt: 'สร้างเมื่อ',
    states: {
      pending: 'รอการชำระ',
      completed: 'ชำระแล้ว',
      expired: 'หมดเวลาชำระ',
      canceled: 'ยกเลิก',
      failed: 'ไม่สำเร็จ',
      refund: 'คืนเงินแล้ว',
      request_refund: 'รอการคืนเงิน'
    },
    membershipType: 'รูปแบบสมาชิก',
    orderRef: 'หมายเลขอ้างอิงผู้สมัคร',
    approvePayment: 'ชำระเงิน',
    savePaymentSuccess: 'บันทึกการชำระเงินสำเร็จ',
    paymentDetail: 'ข้อมูลการชำระเงิน',
    payRef: 'iPay Ref.',
    paymentKey: 'หมายเลขอ้างอิง',
    refundPayment: 'คืนเงิน',
    refundAt: 'วันที่คืนเงิน',
    cancelList: 'ยกเลิกรายการ',
    cancelListModal: {
      title: 'ยกเลิกรายการ',
      message: 'ต้องการยกเลิกรายการชำระเงินนี้'
    },
    type: 'ประเภทใบเสร็จ',
    paymentFirebaseId: 'payment firebase ID',
    membershipFirebaseId: 'membership firebase ID',
    reason: 'เหตุผล'
  },
  error: {
    'This agent already exists': 'มีชื่อนี้อยู่ในระบบแล้ว'
  },
  approvePaymentModal: {
    title: 'กรอกข้อมูลการชำระเงิน',
    date: 'วันที่ชำระเงิน'
  }
}
