export default {
  moduleName: 'นำเข้าข้อมูล',
  form: {
    name: 'ชื่อ',
    type: 'ประเภทข้อมูลในการนำเข้า',
    file: 'นำเข้าไฟล์ (.csv)',
    electionYear: 'ปีการเลือกตั้ง',
    state: 'สถานะ',
    btn: {
      upload: 'อัปโหลด',
      validate: 'ตรวจสอบ',
      process: 'ดำเนินการ'
    }
  }
}
