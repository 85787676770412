export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)

    if (params.search) {
      params.or = [`name||cont||${query.search}`]
      delete params.search
    }

    return http.get('party', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`party/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`party`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`party/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`party/${id}`)
      .then(response => response.data)
  }
})
