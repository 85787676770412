<template lang="pug">
  div
    input.form-control(
      type="text",
      v-model="model",
      v-bind="$attrs"
      v-maska="mask",
      placeholder='x-xxxx-xxxxx-xx-x',
      @maska="onMaska",
      :disabled="disabled"
    )
</template>
<script>
import { mask, maska } from 'maska'
import utils from '@/libs/utils'

export default {
  inheritAttrs: false,
  directives: { maska },
  props: {
    value: {
    },
    $v: {
    },
    disabled: {
      default: false
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value,
      mask: '#-####-#####-##-#'
    }
  },
  watch: {
    value (value) {
      this.model = mask(value, this.mask)
    }
  },
  methods: {
    onMaska (event) {
      const rawValue = event.target.dataset.maskRawValue
      this.$emit('input', utils.convert(rawValue, this.localOption.type))
    }
  }
}
</script>
