<template lang="pug">
  #app
    router-view
    //- customizer
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    themeName () {
      return this.getThemeMode.dark ? 'dark-theme' : ' '
    },
    rtl () {
      return this.getThemeMode.rtl ? 'rtl' : ' '
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Welcome',
      // all titles will be injected into this template
      titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
      bodyAttrs: {
        class: [this.themeName]
      },
      htmlAttrs: {
        dir: this.rtl
      }
    }
  }
}
</script>

<style>

</style>
