export default {
  moduleName: 'ระดับของสมาชิก',
  form: {
    id: 'ID',
    position: 'ตำแหน่ง',
    memberId: 'สมาชิก',
    provinceId: 'จังหวัด',
    areaNo: 'เขตที่',
    priority: 'ความสำคัญ'
  },
  positions: {
    mp: 'ส.ส.',
    executive_director: 'กรรมการบริหาร',
    ptp_team: 'ทีมเพื่อไทย'
  }
}
