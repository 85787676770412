import i18n from '@/langs'

const errorHelper = {
  fromCode (error) {
    if (error.code) {
      const path = `error.code.${error.code}`
      if (i18n.te(path)) {
        return i18n.t(path)
      }
    }
    return error.message || error.toString()
  },
  validation (name, type, options = {}) {
    const path = `error.validation.${type}`
    const data = Object.assign({}, options, {
      name,
      type
    })
    if (i18n.te(path)) {
      return i18n.t(path, data)
    } else {
      return i18n.t('error.validation.default', data)
    }
  }
}

export default {
  install (Vue) {
    Vue.prototype.$error = errorHelper
  }
}
