<template lang="pug">
.modal.fade(:class="{ 'show': modalShow, active: active }")
  .modal-backdrop.fade(:class="{ 'show': modalShow }", @click="backgroundClick()")
  .close-button(
      v-if="closeButton"
      @click="hide"
    )
      i.fas.fa-times
  .modal-dialog(
    :class="[ size ]",
    :style="{ '--content-bg-color': contentBackground }"
  )
    .modal-content()
      slot
</template>

<script>
export default {
  props: {
    overlayClose: {
      default: false,
      type: Boolean
    },
    size: {
      default: 'modal-md'
    },
    contentBackground: {
      default: 'white',
      type: String
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    escClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      modalShow: false,
      active: false,
      paddingRight: 0,
      backdropElement: null
    }
  },
  created () {
    if (this.escClose) {
      document.addEventListener('keyup', this.processKeypress)
    }
  },
  beforeDestroy () {
    if (this.escClose) {
      document.removeEventListener('keyup', this.processKeypress)
    }
  },
  methods: {
    show (options = {}) {
      this.setBodyClass(true)
      this.active = true
      // document.body.style.paddingRight = this.getScrollbarWidth() + 'px'
      setTimeout(() => {
        this.modalShow = true
        if (options.onComplete) {
          options.onComplete()
        }
      }, 1)
    },
    hide () {
      this.modalShow = false
      setTimeout(() => {
        document.body.style.paddingRight = '0px'
        this.setBodyClass(false)
        this.active = false
        this.$emit('hide')
      }, 150)
    },
    backgroundClick () {
      if (this.overlayClose) {
        this.hide()
      }
    },
    setBodyClass (isOpen) {
      if (isOpen) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    },
    getScrollbarWidth () {
      var inner = document.createElement('p')
      inner.style.width = '100%'
      inner.style.height = '200px'
      var outer = document.createElement('div')
      outer.style.position = 'absolute'
      outer.style.top = '0px'
      outer.style.left = '0px'
      outer.style.visibility = 'hidden'
      outer.style.width = '200px'
      outer.style.height = '150px'
      outer.style.overflow = 'hidden'
      outer.appendChild(inner)
      document.body.appendChild(outer)
      var w1 = inner.offsetWidth
      outer.style.overflow = 'scroll'
      var w2 = inner.offsetWidth
      if (w1 === w2) w2 = outer.clientWidth
      document.body.removeChild(outer)
      return (w1 - w2)
    },
    processKeypress (e) {
      if (e.keyCode === 27) {
        this.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    background: var(--content-bg-color);
    z-index: 1050;
    // height: calc(100vh - 50px);
    margin: 25px auto;
    &.modal-fullwidth {
      margin-top: 0;
      margin-bottom: 0;
      max-width: calc(100%);
    }
  }
  .modal-content {
    // max-height: 100%;
    background: transparent;
  }
  &.active {
    display: block;
  }
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.34);
  opacity: 0.7;
}
.close-button {
  $size: 40px;

  width: $size;
  height: $size;
  position: fixed;
  z-index: 1060;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  cursor: pointer;

  > i {
    font-size: 20px;
  }
}
</style>
