const pathName = 'family-membership'

export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)
    // for search
    if (params.search) {
      const q = params.search
      params.or = [`firstName||cont||${q}`, `lastName||cont||${q}`, `idCard||cont||${q}`]
      delete params.search
    }

    return http.get(`${pathName}`, { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({}, query)
    return http.get(`${pathName}/${id}`, { params })
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`${pathName}/${id}`)
      .then(response => response.data)
  }
})
