// import http from '@/libs/http'
// import utils from '@/libs/utils'
import membershipHelper from '@/libs/membershipHelper'
export default (http) => ({
  count (query) {
    const params = Object.assign({
      per_page: 1,
      page: 1,
      sort: 'id,DESC'
    }, query)

    return http.get('membership', { params })
      .then(response => response.data)
  },
  findAll (query) {
    const params = Object.assign({
      per_page: 10,
      page: 1,
      sort: 'id,DESC'
    }, query)

    if (params.search) {
      params.or = [
        `member.firstName||cont||${query.search}`,
        `member.lastName||cont||${query.search}`,
        `member.idCard||cont||${query.search}`,
        `partyId||cont||${query.search.toUpperCase()}`
      ]
      delete params.search
    }

    return http.get('membership', { params })
      .then(response => response.data)
  },
  search (query) {
    const params = Object.assign({
      per_page: 10,
      page: 1,
      sort: 'id,DESC'
    }, query)

    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.get('membership/search', { params })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`membership/${id}`, { params })
      .then(response => response.data)
  },
  save (data, id) {
    const postData = this._clean(data)
    if (id) {
      return this.update(postData, id)
    } else {
      return this.create(postData)
    }
  },
  create (data) {
    return http.post(`membership`, data)
      .then(response => response.data)
  },
  update (data, id) {
    return http.put(`membership/${id}`, data)
      .then(response => response.data)
  },
  _clean (data) {
    const result = Object.assign({}, data)
    // assign type to address array
    if (result.memberDto.addresses[0]) {
      result.memberDto.addresses[0].addressType = 'permanent'
    }
    if (result.memberDto.addresses[1]) {
      result.memberDto.addresses[1].addressType = 'contact'
    }

    // delete unwanted address
    if (result.memberDto.addresses[1] && result.memberDto.addresses[1].sameAddress === true) {
      result.memberDto.addresses.splice(1, 1)
    }

    // calculate expired date if create mode
    const { appliedAt, membershipType } = data.membershipDto
    if (!data.membershipDto.id) {
      result.membershipDto.expiredAt = membershipHelper.calculateMembershipExpireDate(appliedAt, membershipType)
    } else {
      // if edit mode check only permanent type
      if (membershipType === 'permanent') {
        result.membershipDto.expiredAt = null
      }
    }

    // update payments
    // if (result.membershipDto.paymentReceipts) {
    //   result.membershipDto.paymentReceipts.forEach(receipt => {
    //     receipt.bookNo = parseInt(receipt.bookNo) || 0
    //     receipt.receiptNo = parseInt(receipt.receiptNo) || 0
    //   })
    // }

    // resignedAt
    if (['draft', 'review', 'approved'].indexOf(result.membershipDto.state) !== -1) {
      result.membershipDto.resignedAt = null
    }

    return result
  },
  bulkUpdate (memberships) {
    return http.put(`membership/bulk`, memberships)
      .then(response => {
        return response.data
      })
  },
  renew (membershipId, data) {
    return http.post(`membership/${membershipId}/renew`, data)
      .then(response => {
        return response.data
      })
  },
  statisticProvinces (params) {
    return http.get('membership/statistic/provinces', { params })
      .then(response => {
        return response.data.provinces
      })
  },
  statisticAreas (params) {
    return http.get('membership/statistic/areas', { params })
      .then(response => {
        return response.data.areas
      })
  },
  delete (id) {
    return http.delete(`membership/${id}`)
      .then(response => response.data)
  },
  registrarApprove (ids) {
    return http.post(`membership/registrar-approve`, ids.map(id => ({ id })))
      .then(response => {
        return response.data
      })
  },
  markPrintApplicant (data) {
    return http.post('membership/mark-print-applicant', data)
      .then(response => response.data)
  },
  futureActiveMembership (params) {
    return http.get('membership/statistic/future-active-membership', { params })
      .then(response => response.data)
  },
  printApplicant (params) {
    return http.get('membership/print-applicant',
      { responseType: 'blob',
        headers: { Accept: 'application/pdf' },
        params: params
      }).then(response => response.data)
  }
})
