<template lang="pug">
  div.mt-2
    //- pre m {{ models }}
    //- pre v {{ value }}
    div
      .d-block(v-for="(data, index) in localOptions.data", :key="data.name", v-if="models[data.value]")
        b-form-checkbox.d-block.mr-2(v-model='models[data.value].checkbox', @input="onInput") {{ data.label }}
        .mt-2(v-if="data.inputs && models[data.value].checkbox")
          .d-flex(v-for="input in data.inputs")
            label.mr-2 {{ input.label }}
            input.form-control(type="text", v-model="models[data.value].inputs[input.name]", @input="onInput")
    //- label.radio.radio-primary.d-inline-block.mr-2.mt-2(v-for="(value, i) in options.data")
    //-   input(type="radio", )
    //-   span {{ value }}
    //-   span.checkmark
</template>
<script>
// import utils from '@/libs/utils'
export default {
  props: {
    value: {
      Type: Array,
      default: () => []
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  data () {
    const localOptions = Object.assign({
      otherCheckboxField: null,
      otherModelName: null,
      itemProperty: 'item',
      data: {}
    }, this.options)
    return {
      models: {},
      inputs: {},
      localOptions
    }
  },
  computed: {
    // isCheckOther () {
    //   return this.model[this.localOptions.otherCheckboxField]
    // }
  },
  watch: {
    value () {
      this.processDataToModel()
    }
    // isCheckOther (val) {
    //   if (!val) {
    //     this.model[this.localOptions.otherModelName] = ''
    //   }
    // }
  },
  mounted () {
    this.processDataToModel()
  },
  methods: {
    processDataToModel () {
      const models = {}
      this.localOptions.data.forEach(list => {
        const item = this.value.find(val => {
          return val[this.localOptions.itemProperty] === list.value
        })
        const inputs = {}
        if (list.inputs) {
          list.inputs.forEach(input => {
            inputs[input.name] = item ? item[input.name] : null
          })
        }
        models[list.value] = {
          checkbox: (item !== undefined),
          inputs
        }
      })
      this.models = models
    },
    onInput (event) {
      const selectedValue = []
      Object.keys(this.models).forEach(key => {
        const model = this.models[key]
        if (model.checkbox) {
          const data = {}
          data[this.localOptions.itemProperty] = key
          Object.keys(model.inputs).forEach(inputKey => {
            data[inputKey] = model.inputs[inputKey]
          })
          selectedValue.push(data)
        }
      })
      this.$emit('input', selectedValue)
    }
  }
}
</script>
