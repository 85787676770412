import { Buffer } from 'buffer'

export default (http) => ({
  getPhotoUrl (uid, getOption) {
    const option = Object.assign({
      type: 'url', // url , file
      fileResponseType: undefined // null, blob, arraybuffer
    }, getOption)
    const { type, fileResponseType } = option
    // type = 'url', responseType = 'arraybuffer'
    const options = {
      params: {
        path: `idPhotos/${uid}.jpg`,
        type
      }
    }
    if (type === 'file') {
      options.responseType = option.fileResponseType
    }

    return http.get('mobile-agent-file/user-document', options)
      .then(response => {
        if (type === 'file' && fileResponseType === 'arraybuffer') {
          return Buffer.from(response.data, 'binary').toString('base64')
        }
        return response.data
      })
  },
  getSignatureUrl (uid, getOption) {
    const option = Object.assign({
      type: 'url', // url , file
      fileResponseType: undefined // null, blob, arraybuffer
    }, getOption)
    const { type, fileResponseType } = option
    // type = 'url', responseType = 'arraybuffer'
    const options = {
      params: {
        path: `signatures/${uid}.jpg`,
        type
      }
    }
    if (type === 'file') {
      options.responseType = option.fileResponseType
    }

    return http.get('mobile-agent-file/user-document', options)
      .then(response => {
        if (type === 'file' && fileResponseType === 'arraybuffer') {
          return Buffer.from(response.data, 'binary').toString('base64')
        }
        return response.data
      })
  }
})
