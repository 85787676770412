<template lang="pug">
  div
    dropdown(v-model="model", @input="onInput", :options="areaDropdownOptions", :disabled="disabled")
</template>
<script>

export default {
  props: {
    value: {
      type: Number
    },
    electionYearId: {
      type: Number,
      required: true
    },
    provinceId: {
      type: Number
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    },
    resetVModelIfProvinceChange: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const localOptions = Object.assign({
    }, this.options)
    return {
      localOptions,
      model: null,
      areaDropdownOptions: {
        data: []
      }
    }
  },
  computed: {
  },
  watch: {
    value (value) {
      this.model = value
    },
    provinceId (value, oldValue) {
      // reset area id if province id change
      if (value !== oldValue && this.model !== null && this.resetVModelIfProvinceChange) {
        this.model = null
        this.$emit('input', null)
      }
      if (value) {
        this.loadData()
      } else {
        this.$set(this.areaDropdownOptions, 'data', [])
      }
    },
    electionYearId (value, oldValue) {
      // reset area id if province id change
      if (value !== oldValue && this.model !== null && this.resetVModelIfProvinceChange) {
        this.model = null
        this.$emit('input', null)
      }
      if (value) {
        this.loadData()
      } else {
        this.$set(this.areaDropdownOptions, 'data', [])
      }
    }
  },
  mounted () {
    // init model when has provinceId, value
    if (this.provinceId && this.value) {
      this.model = this.value
      this.loadData()
    }
  },
  methods: {
    loadData () {
      const query = {
        sort: 'areaNo,ASC',
        filter: [`electionYearId||eq||${this.electionYearId}`, `provinceId||eq||${this.provinceId}`]
      }
      return this.$services.election.findAllArea(query)
        .then(areas => {
          let areasNoSet = areas.reduce((acc, area) => {
            acc.add(area.areaNo)
            return acc
          }, new Set())
          let areasNo = [...areasNoSet]
          areasNo = areasNo.map(no => ({
            label: no,
            value: parseInt(no)
          }))
          this.$set(this.areaDropdownOptions, 'data', areasNo)
        })
    },
    onInput () {
      this.$emit('input', parseInt(this.model))
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
