<template lang="pug">
  .row
    .col-12
      //- pre {{ model }}
      //- pre {{ $v }}
      .row
        .form-group(:class="[localOptions.inputClass[0]]")
          label
            span.mr-1 อำเภอ / เขต
            //- span.text-danger(v-if="$v[this.getField('DistrictId')].$params.required") *
          div
            fake-input(v-if="model", v-model="model", :showDelete="true", @delete="reset") {{ query.district }}
            suggestions(
              v-else
              v-model='query.district',
              :options='suggestionsOption',
              :on-input-change='onDisTrictInputChange',
              @selected="onSelected")
              .single-item.address(slot='item', slot-scope='props')
                template()
                  div
                    span(v-html="replaceBoldTag(props.item.district, query.district)") {{ props.item.district }}
                    span.separate &gt;&gt;
                    span {{ props.item.province }}
          div
            //- small.form-text.text-danger(v-show="!$v[this.getField('DistrictId')].required && $v[this.getField('DistrictId')].$dirty") {{ getValidationMessage('อำเภอ / เขต', 'required') }}
        .form-group(:class="[localOptions.inputClass[1]]")
          label จังหวัด
          input.form-control(type='text', placeholder='', v-model="query.province", disabled)
          //- small.form-text.text-danger(v-show="!$v[this.getField('ProvinceId')].required && $v[this.getField('ProvinceId')].$dirty") {{ getValidationMessage('จังหวัด', 'required') }}
</template>
<script>
import utils from '@/libs/utils'

export default {
  props: {
    value: {
      type: Number
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const localOptions = Object.assign({
      prefix: '',
      inputClass: ['col-6', 'col-6']
    }, this.options)
    return {
      model: this.value,
      localOptions,
      suggestionsOption: {
        inputClass: 'form-control',
        placeholder: ''
      },
      query: {
        subdistrict: '',
        district: '',
        province: ''
      }
    }
  },
  computed: {
  },
  watch: {
    value (value) {
      if (value) {
        this.displayDistrictInput(value)
      }
    }
  },
  mounted () {
    const districtId = this.model
    if (districtId) {
      this.displayDistrictInput(districtId)
    }
  },
  methods: {
    async onDisTrictInputChange (query) {
      if (query.length < 2) {
        return []
      }
      const response = await this.$services.location.findAllDistrict({}, query)
      const result = response.map(r => {
        return {
          district: r.name,
          districtId: r.id,
          province: r.province.name,
          provinceId: r.province.id
        }
      })
      return result
    },
    onSelected (item) {
      if (item.district) {
        this.model = item.districtId
        this.query.district = item.district
        this.$emit('input', item.districtId)
      }
      if (item.province) {
        this.query.province = item.province
      }
    },
    replaceBoldTag (text, query) {
      text = text.toString()
      return text.replace(query, `<strong>${query}</strong>`)
    },
    getValidationMessage (fieldLabel, type) {
      return utils.getValidationMessage(fieldLabel, type)
    },
    displayDistrictInput (id) {
      this.$services.location.findDistrict(id)
        .then(row => {
          this.query.district = row.name
          this.query.province = row.province.name
        })
    },
    reset () {
      this.query = {
        subdistrict: '',
        district: '',
        province: ''
      }
      this.model = null
      this.$emit('input', null)
    }
  }
}
</script>
