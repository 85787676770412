export default {
  path: 'member-group',
  component: () => import('@/layouts/membership'), // dashboard
  children: [
    {
      name: 'member-group-index',
      path: '',
      component: () => import('@/views/memberGroup/Index'),
      meta: {
        acl: { module: 'memberGroup', 'action': 'Read-All' }
      }
    },
    {
      name: 'member-group-create',
      path: 'create',
      component: () => import('@/views/memberGroup/Create'),
      meta: {
        acl: { module: 'memberGroup', 'action': 'Create-One' }
      }
    },
    {
      name: 'member-group-update',
      path: ':id',
      component: () => import('@/views/memberGroup/Update'),
      meta: {
        acl: { module: 'memberGroup', 'action': 'Update-One' }
      }
    },
    {
      name: 'member-group-member-index',
      path: ':id/member',
      component: () => import('@/views/memberGroup/member/Index'),
      meta: {
        acl: { module: 'memberGroup', 'action': 'Read-All' }
      }
    }
  ]
}
