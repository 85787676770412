<template lang="pug">
el-date-picker.w-100(
  v-model="model",
  type="daterange",
  :start-placeholder="$t('common.start')",
  :end-placeholder="$t('common.end')",
  format="d MMM yyyy"
  @change="onDateRangeChange"
)
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'DateRangeSelector',
  props: {
    value: {
      type: Array,
      required: false
    },
    endWithMidnight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  watch: {
    value (value) {
      this.model = value
    }
  },
  methods: {
    onDateRangeChange (dates) {
      if (dates) {
        const [ start, end ] = dates
        if (this.endWithMidnight) {
          end.setHours(23)
          end.setMinutes(59)
          end.setSeconds(59)
          end.setMilliseconds(999)
        }
        this.model = [start.toISOString(), end.toISOString()]
      } else {
        this.model = null
      }
      this.$emit('input', this.model)
      this.$emit('change', this.model)
    }
  }
})
</script>
