export default {
  name: 'บัญชีตัวแทนเขต',
  fullName: 'ชื่อ - สกุล',
  username: 'ชื่อผู้ใช้งาน',
  status: 'สถานะ',
  haveAccount: 'มีบัญชีผู้ใช้งาน',
  account: 'บัญชีตัวแทนเขต',
  idCard: 'บัตรประจำตัวประชาชน',
  createdAt: 'สร้างเมื่อ',
  form: {
    fullName: 'ชื่อ - สกุล',
    idCard: 'บัตรประจำตัวประชาชน',
    activeStatus: 'สถานะใช้งาน',
    username: 'ชื่อผู้ใช้งาน',
    password: 'รหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    changePassword: 'เปลี่ยนรหัสผ่าน',
    cancelChangePassword: 'ยกเลิกเปลี่ยนรหัสผ่าน'

  }
}
