export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      per_page: 10,
      page: 1,
      sort: 'id,DESC'
    }, query)

    if (params.search) {
      params.or = [`username||cont||${query.search}`, `firstName||cont||${query.search}`, `lastName||cont||${query.search}`]
      delete params.search
    }

    return http.get('staff', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`staff/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`staff`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.patch(`staff/${data.id}`, data)
      .then(response => response.data)
  }
})
