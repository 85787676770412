<template lang="pug">
  div.mt-2
    //- pre {{ model }}
    b-form-group
      b-form-radio.d-inline-block.mr-2(
        :key="i"
        v-for="(m, i) in options.data",
        :value="m.value", v-model="model",
        @input="onInput") {{ m.label }}
    //- label.radio.radio-primary.d-inline-block.mr-2.mt-2(v-for="(value, i) in options.data")
    //-   input(type="radio", )
    //-   span {{ value }}
    //-   span.checkmark
</template>
<script>
import utils from '@/libs/utils'
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  mounted () {
  },
  methods: {
    onInput (event) {
      this.$emit('input', utils.convert(this.model, this.options.type))
    }
  }
}
</script>
