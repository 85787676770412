export default (http) => ({

  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)
    if (params.search) {
      const q = params.search
      params.or = [`name||cont||${q}`]
      delete params.search
    }
    return http.get('import-task', { params })
      .then(response => response.data)
  },
  find (id, query = {}) {
    const params = Object.assign({}, query)

    return http.get(`import-task/${id}`, { params })
      .then(response => response.data)
  },
  create (data) {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })
    return http.post(`import-task`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  validate (id) {
    return http.post(`import-task/${id}/validate`)
  },
  process (id) {
    return http.post(`import-task/${id}/process`)
  },
  delete (id) {
    return http.delete(`import-task/${id}`)
      .then(response => response.data)
  }

  // update (data) {
  //   const putdata = this._clean(data)
  //   return http.put(`location/district/${putdata.id}`, putdata)
  //     .then(response => response.data)
  // },
  // delete (id) {
  //   return http.delete(`location/district/${id}`)
  //     .then(response => response.data)
  // },
  // _clean (data) {
  //   const result = Object.assign({}, data)
  //   if (result.province) {
  //     delete result.province
  //   }
  //   return result
  // }
})
