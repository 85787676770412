export default {
  name: 'อำเภอ',
  provinceId: 'จังหวัด',
  form: {
    code: 'รหัสอำเภอ',
    name: 'ชื่ออำเภอ',
    province: 'จังหวัด',
    createdAt: 'สร้างเมื่อ'
  }
}
