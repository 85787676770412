
import store from '@/store'
import { STAFF_ROLES } from '@/data/constant'

const ACL = {
  staff: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  branch: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  membership: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff, STAFF_ROLES.area_agent_viewer, STAFF_ROLES.branch_committee_viewer],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Delete-One': [STAFF_ROLES.admin],
    'Registrar-Approve': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar]
  },
  membershipCard: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar]
  },
  institute: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff]
  },
  education: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff]
  },
  occupation: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff]
  },
  agent: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff]
  },
  setting: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar, STAFF_ROLES.staff]
  },
  subdistrict: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  electionArea: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  district: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  report: {
    generate: [STAFF_ROLES.admin, STAFF_ROLES.accounting]
  },
  print: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar]
  },
  ect: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.registrar]
  },
  paymentReceipt: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting]
  },
  memberGroup: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  waitingMember: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  mobileAgent: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting]
  },
  mobileAgentAdmin: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  onlineChannel: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.accounting]
  },
  onlineChannelAdmin: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  familyMembership: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  memberLevel: {
    'Read-All': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Read-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Create-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Update-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff],
    'Delete-One': [STAFF_ROLES.admin, STAFF_ROLES.supervisor, STAFF_ROLES.staff]
  },
  blacklist: {
    'Real-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  areaAgentAccount: {
    'Real-All': [STAFF_ROLES.admin]
  },
  profileChangeRequest: {
    'Read-All': [STAFF_ROLES.admin],
    'Read-One': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin],
    'Update-One': [STAFF_ROLES.admin],
    'Delete-One': [STAFF_ROLES.admin]
  },
  electionImport: {
    'Real-All': [STAFF_ROLES.admin],
    'Create-One': [STAFF_ROLES.admin]
  }
}

const acl = {
  validate ({ module, action }) {
    if (!store.getters['auth/isAuthenticated']) {
      return false
    }
    if (ACL[module]) {
      if (ACL[module][action]) {
        const lists = ACL[module][action]
        const valid = lists.indexOf(store.state.auth.loggedInUser.role) !== -1
        return valid
      }
      return false
    }
    return true
  }
}

export default {
  install (Vue) {
    Vue.prototype.$acl = acl
  }
}
