
export default (http) => ({
  findAllArea (query) {
    const params = Object.assign({
    }, query)
    return http.get(`election/area/`, { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  }
})
