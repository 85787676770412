export default {
  name: 'ผู้หาสมาชิก',
  form: {
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    name: 'ชื่อ - สกุล',
    phone: 'โทรศัพท์',
    createdAt: 'สร้างเมื่อ'
  },
  error: {
    'This agent already exists': 'มีชื่อนี้อยู่ในระบบแล้ว'
  }
}
