import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import App from './App.vue'
import router from './router'
import GullKit from './plugins/gull.kit'
import ACLPlugin from './plugins/acl'
import ErrorPlugin from './plugins/error'
import ServicePlugin from './plugins/service'
import ShortKey from 'vue-shortkey'
import ClickOutside from './directives/click-outside'
import Invalid from './directives/invalid'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import ElementUI from 'element-ui'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueLogger from 'vuejs-logger'
import VueSignaturePad from 'vue-signature-pad'

import elementUILocale from 'element-ui/lib/locale/lang/th'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import '@/assets/styles/sass/element-ui.scss'
import '@/assets/styles/sass/base.scss'
import pk from '../package'
// import "babel-polyfill";
// import es6Promise from "es6-promise";
// es6Promise.polyfill();
import store from './store'
import i18n from './langs'
// import './registerServiceWorker'
import './registerComponents'
import './filters'

import 'swiper/dist/css/swiper.css'
import './assets/fonts/tfpimai/stylesheet.scss'

const isProduction = process.env.VUE_APP_ENV === 'production'

Vue.use(GullKit)
Vue.directive('click-outside', ClickOutside)
Vue.directive('invalid', Invalid)
Vue.use(ACLPlugin)
Vue.use(ErrorPlugin)
Vue.use(ServicePlugin)
Vue.use(ShortKey)
Vue.use(ElementUI, {
  locale: elementUILocale
})
Vue.use(InfiniteLoading)
Vue.use(VueAwesomeSwiper)
Vue.use(VueExcelXlsx)
Vue.use(VueSignaturePad)

const options = {
  isEnabled: !isProduction,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, options)

Vue.config.productionTip = false

store.dispatch('auth/setAuthenHeader')

if (['production', 'staging', 'dev'].indexOf(process.env.VUE_APP_NAME) !== -1) {
  Sentry.init({
    Vue,
    dsn: 'https://9690d8beb46a48d59aa751edce9d0efb@telemetry.vive.co.th/2',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.VUE_APP_NAME,
    release: `${pk.version}`,
    tracesSampleRate: 0.2
  })
}

// throw new Error('test sentry')
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
