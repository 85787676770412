export default {
  path: '/payment-receipt',
  component: () => import('@/layouts/paymentReceipt'), // receipt
  children: [
    {
      name: 'payment-receipt-index',
      path: '',
      component: () => import('@/views/paymentReceipt/Index'),
      meta: {
        acl: { module: 'paymentReceipt', 'action': 'Read-All' }
      }
    },
    {
      name: 'payment-receipt-dashboard',
      path: 'dashboard',
      component: () => import('@/views/paymentReceipt/Dashboard'),
      meta: {
        acl: { module: 'paymentReceipt', 'action': 'Read-All' }
      }
    },
    {
      name: 'payment-receipt-report-duplicate',
      path: 'report-duplicate',
      component: () => import('@/views/paymentReceipt/ReportDuplicate'),
      meta: {
        acl: { module: 'paymentReceipt', 'action': 'Update-One' }
      }
    },
    {
      name: 'payment-receipt-print',
      path: 'print',
      component: () => import('@/views/paymentReceipt/Print'),
      meta: {
        acl: { module: 'paymentReceipt', 'action': 'Update-One' }
      }
    },
    {
      name: 'payment-receipt-view',
      path: ':id',
      component: () => import('@/views/paymentReceipt/Index'),
      meta: {
        acl: { module: 'paymentReceipt', 'action': 'Update-One' }
      }
    }
  ]
}
