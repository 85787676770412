export default {
  path: 'membership',
  component: () => import('@/layouts/membership'), // dashboard
  children: [
    {
      name: 'membership-index',
      path: '',
      component: () => import('@/views/membership/Index'),
      meta: {
        acl: { module: 'membership', 'action': 'Read-One' }
      }
    },
    {
      name: 'membership-create',
      path: 'create',
      component: () => import('@/views/membership/Create')
    },
    {
      name: 'membership-dashboard',
      path: 'dashboard',
      component: () => import('@/views/membership/Dashboard'),
      meta: {
        acl: { module: 'membership', 'action': 'Read-All' }
      }
    },
    {
      name: 'membership-ect',
      path: 'ect',
      component: () => import('@/views/membership/Ect'),
      meta: {
        acl: { module: 'membership', 'action': 'Read-All' }
      }
    },
    {
      name: 'membership-group',
      path: 'group',
      component: () => import('@/views/membership/Group.vue'),
      meta: {
        acl: { module: 'membership', 'action': 'Read-One' }
      }
    },
    {
      name: 'membership-review',
      path: 'review',
      component: () => import('@/views/membership/Review'),
      meta: {
        acl: { module: 'membership', 'action': 'Read-All' }
      }
    },
    {
      name: 'membership-registrar-approve',
      path: 'registrar-approve',
      component: () => import('@/views/membership/RegistrarApprove'),
      meta: {
        acl: { module: 'membership', 'action': 'Registrar-Approve' }
      }
    },
    {
      name: 'membership-update',
      path: ':id',
      component: () => import('@/views/membership/Update'),
      meta: {
        acl: { module: 'membership', 'action': 'Update-One' }
      }
    },
    {
      path: '/print/membership-card',
      component: () => import('@/layouts/print'), // dashboard
      children: [
        {
          name: 'membership-card-index',
          path: '',
          component: () => import('@/views/membershipCard/Index')
        }
      ]
    },
    {
      path: '/print/membership-applicant',
      component: () => import('@/layouts/print'), // dashboard
      children: [
        {
          name: 'print-membership-applicant',
          path: '',
          component: () => import('@/views/membership/PrintApplicant')
        }
      ]
    },
    {
      path: '/profile-change-request',
      component: () => import('@/layouts/membership'), // ect
      children: [
        {
          name: 'profile-change-request-index',
          path: '',
          component: () => import('@/views/profileChangeRequest/Index')
        },
        {
          name: 'profile-change-request-review',
          path: 'review/:id',
          component: () => import('@/views/profileChangeRequest/Review.vue')
        }
      ]
    }
  ]
}
