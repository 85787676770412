<template lang="pug">
  div
    //- pre {{ model }}
    //- pre {{ value }}
    select.custom-select(v-model="model", @input="onInput" :disabled="disabled")
      option(selected='selected' :value="null" ) {{ localOptions.chooseText }}
      option(v-for="(m, i) in options.data" :value="m[localOptions.valueKey]") {{ m[localOptions.labelKey] }}
</template>
<script>
import utils from '@/libs/utils'
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {
        return {
          type: 'String'
        }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const localOptions = Object.assign({
      chooseText: 'เลือก',
      valueKey: 'value',
      labelKey: 'label'
    }, this.options)

    return {
      localOptions,
      model: this.value
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  mounted () {
  },
  methods: {
    onInput (event) {
      let value = event.target.value
      this.model = value
      this.$emit('input', utils.convert(value, this.options.type))
    }
  }
}
</script>
