export default [
  {
    path: '/setting/institute',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'institute-index',
        path: '',
        component: () => import('@/views/institute/Index')
      }
    ]
  },
  {
    path: '/setting/branch',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'branch-index',
        path: '',
        component: () => import('@/views/branch/Index')
      }
    ]
  },
  {
    path: '/setting/occupation',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'occupation-index',
        path: '',
        component: () => import('@/views/occupation/Index')
      }
    ]
  },
  {
    path: '/setting/education-level',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'education-level-index',
        path: '',
        component: () => import('@/views/educationLevel/Index')
      }
    ]
  },
  {
    path: '/setting/party',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'party-index',
        path: '',
        component: () => import('@/views/party/Index')
      }
    ]
  },
  {
    path: '/setting/agent',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'agent-index',
        path: '',
        component: () => import('@/views/agent/Index')
      }
    ]
  },
  {
    path: '/setting/subdistrict',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'subdistrict-index',
        path: '',
        component: () => import('@/views/subdistrict/Index')
      }
    ]
  },
  {
    path: '/setting/election-area',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'election-area-index',
        path: '',
        component: () => import('@/views/electionArea/Index')
      }
    ]
  },
  {
    path: '/setting/district',
    component: () => import('@/layouts/setting'), // dashboard
    children: [
      {
        name: 'district-index',
        path: '',
        component: () => import('@/views/district/Index')
      }
    ]
  },
  {
    path: '/setting/ect',
    component: () => import('@/layouts/setting'), // ect
    children: [
      {
        name: 'ect-index',
        path: '',
        component: () => import('@/views/ect/Index')
      }
    ]
  },
  {
    path: '/setting/title',
    component: () => import('@/layouts/setting'), // ect
    children: [
      {
        name: 'title-index',
        path: '',
        component: () => import('@/views/title/Index')
      }
    ]
  },
  {
    path: '/setting/blacklist',
    component: () => import('@/layouts/setting'), // ect
    children: [
      {
        name: 'blacklist-index',
        path: '',
        component: () => import('@/views/blacklist/Index')
      }
    ]
  },
  {
    path: '/setting/area-agent-account',
    component: () => import('@/layouts/setting'), // ect
    children: [
      {
        name: 'area-agent-account-index',
        path: '',
        component: () => import('@/views/areaAgentAccount/Index')
      }
    ]
  },
  {
    path: '/setting/import',
    component: () => import('@/layouts/setting'), // ect
    children: [
      {
        name: 'import-index',
        path: '',
        component: () => import('@/views/import/Index')
      },
      {
        name: 'import-create',
        path: 'create',
        component: () => import('@/views/import/Create')
      },
      {
        name: 'import-update',
        path: ':id',
        component: () => import('@/views/import/Update')
      }
    ]
  }
]
