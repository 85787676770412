<template lang="pug">

input(
  v-model="model",
  v-maska="mask",
  v-bind="$attrs",
  :type="type",
  @blur="$emit('blur', $event)"
  @maska="onMaska",
)
</template>

<script>
import { mask, maska } from 'maska'
import utils from '@/libs/utils'

export default {
  inheritAttrs: false,
  directives: { maska },
  props: {
    value: {},
    mask: {
      type: [String, Array],
      required: true
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value
    }
  },
  watch: {
    value (value) {
      this.model = mask((value || ''), this.mask)
    }
  },
  methods: {
    onMaska (event) {
      const rawValue = event.target.dataset.maskRawValue
      this.$emit('input', utils.convert(rawValue, this.localOption.type))
    }
  }
}
</script>
