const baseName = 'family-membership'

export default {
  path: `/${baseName}`,
  component: () => import('@/layouts/auth'),
  children: [
    {
      path: '',
      name: `${baseName}-index`,
      component: () => import('@/views/familyMembership/Index')
    },
    {
      path: ':id',
      name: `${baseName}-review`,
      component: () => import('@/views/familyMembership/Review')
    }
  ]
}
