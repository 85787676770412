<template lang="pug">
  div
    //- pre {{ value }}
    select.custom-select.ml-2(, v-model="field", v-bind="$attrs", style="width: 130px;", @input="onFieldChange")
      option(:value="null") {{ $t('common.choose') }}
      option(v-for="(option, key) in options", :value="key", :key="key") {{ option }}
    b-dropdown.ml-2(:text="orderTypeText", size="sm")
      b-dropdown-item(@click.prevent="onTypeChange('ASC')") {{ orderOptions.ASC }}
      b-dropdown-item(@click.prevent="onTypeChange('DESC')") {{ orderOptions.DESC }}
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: 'id,desc'
    },
    options: {
    }
  },
  data () {
    return {
      model: this.value,
      orderOptions: {
        ASC: 'A → Z',
        DESC: 'Z → A'
      },
      type: 'DESC',
      field: 'id'
    }
  },
  computed: {
    orderTypeText () {
      return this.orderOptions[this.type]
    }
  },
  watch: {
    value () {
      this.model = this.value
      this.parseModel()
    }
  },
  mounted () {
    this.parseModel()
  },
  methods: {
    parseModel () {
      const [field, type] = this.model.split(',')
      this.type = type
      this.field = field
    },
    onTypeChange (type) {
      this.type = type
      this.model = `${this.field},${this.type}`
      this.$emit('input', this.model)
    },
    onFieldChange (e) {
      this.field = e.target.value
      this.model = `${this.field},${this.type}`
      this.$emit('input', this.model)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
