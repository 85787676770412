export default (http) => ({
  save (data) {
    const payload = this._cleanPayload(data)
    if (payload.id) {
      return this.update(payload)
    } else {
      return this.create(payload)
    }
  },
  create (data) {
    return http.post(`area-agent-account`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`area-agent-account/${data.id}`, data)
      .then(response => response.data)
  },
  _cleanPayload (data) {
    const temp = Object.assign({}, data)
    if (temp.passwordConfirm) {
      delete temp.passwordConfirm
    }
    if (temp.member) {
      delete temp.member
    }
    return temp
  }
})
