<template lang="pug">
  .vuelidate-error-messages
    div.error.text-danger(v-if="!vuelidate[type] && vuelidate.$dirty && !vuelidate.$pending", v-for="(param, type) in vuelidate.$params")
      div() {{ $error.validation(name, type, getValidationParams(type)) }}
</template>

<script>
export default {
  props: {
    vuelidate: { // eslint-disable-line vue/prop-name-casing
      type: Object,
      default: null,
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  methods: {
    getValidationParams (type) {
      const params = this.vuelidate.$params[type]
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
