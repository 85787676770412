<template lang="pug">
  .row
    //- pre {{ localOptions.showSameAddress }}
    //- pre {{ sameAddress }}
    .col-12(v-if="localOptions.showSameAddress")
      .form-group
        b-form-checkbox(v-model='sameAddress', @input='onSameAddressChange' data-test="same-address-input") เหมือนกับที่อยู่ตามทะเบียนบ้าน
    .col-12(v-if="model && !model.sameAddress")
      .row
        .col-2.form-group
          label
            span.mr-1 {{ $t('membership.address.homeNo') }}
            span.text-danger(v-if="localError()['homeNo'].$params.required") *
          input.form-control(type='text', placeholder='', v-model="model.homeNo")
          small.form-text.text-danger(v-show="!localError()['homeNo'].required && localError()['homeNo'].$dirty")
            | {{ $t('error.validation.required', { name: $t('membership.address.homeNo') } ) }}
        .col-3.form-group
          label {{ $t('membership.address.villageName') }}
          input.form-control(type='text', placeholder='', v-model="model.villageName")
          small.form-text.text-danger(v-show="!localError()['villageName'].includeAtleastOneProperty && localError()['villageName'].$dirty")
            | {{ $t('error.validation.includeAtleastOneProperty') }}
        .col-1.form-group
          label {{ $t('membership.address.villageNo') }}
          input.form-control(type='text', placeholder='', v-model="model.villageNo")
          small.form-text.text-muted
        .col-3.form-group
          label {{ $t('membership.address.alley') }}
          input.form-control(type='text', placeholder='', v-model="model.alley")
          small.form-text.text-muted
        .col-3.form-group
          label {{ $t('membership.address.street') }}
          input.form-control(type='text', placeholder='', v-model="model.street")
          small.form-text.text-muted
        .col-3.form-group
          label
            span.mr-1 {{ $t('membership.address.subdistrict') }}
            span.text-danger(v-if="localError()['subdistrictId'].$params.required") *
          fake-input(v-if="model[getField('subdistrictId')]", :showDelete="true", @delete="reset") {{ query.subdistrict }}
          suggestions(v-else
            v-model='query.subdistrict',
            :options='suggestionsOption',
            :on-input-change='onSubDisTrictInputChange',
            :disabled="sameAddress",
            @selected="onSelected")
            .single-item.address(slot='item', slot-scope='props')
              template()
                div
                  span(v-html="replaceBoldTag(props.item.subdistrict, query.subdistrict)")
                  span.separate &gt;&gt;
                  span {{ props.item.district }}
                  span.separate &gt;&gt;
                  span {{ props.item.province }}
                  span.separate &gt;&gt;
                  span {{ props.item.zipcode }}
          small.form-text.text-danger(v-show="!localError()['subdistrictId'].required && localError()['subdistrictId'].$dirty")
            | {{ $t('error.validation.required', { name: $t('membership.address.subdistrict') } ) }}
        .col-3.form-group
          label {{ $t('membership.address.district') }}
          suggestions(
            v-model='query.district',
            :options='suggestionsOption',
            :on-input-change='onDisTrictInputChange',
            :disabled="true",
            @selected="onSelected")
            .single-item.address(slot='item', slot-scope='props')
              template()
                div
                  span(v-html="replaceBoldTag(props.item.district, query.district)") {{ props.item.district }}
                  span.separate &gt;&gt;
                  span {{ props.item.province }}
        .col-3.form-group
          label {{ $t('membership.address.province') }}
          input.form-control(type='text', placeholder='', v-model="query.province", disabled)
        .col-3.form-group
          label {{ $t('membership.address.zipcode') }}
          fake-input(v-if="model[getField('subdistrictId')]", :showDelete="true", @delete="reset") {{ query.zipcode }}
          suggestions(v-else
            v-model='query.zipcode',
            :options='suggestionsOption',
            :on-input-change='onZipcodeInputChange',
            :disabled="sameAddress",
            @selected="onSelected")
            .single-item.address(slot='item', slot-scope='props')
              template()
                div
                  span {{ props.item.subdistrict }}
                  span.separate &gt;&gt;
                  span {{ props.item.district }}
                  span.separate &gt;&gt;
                  span {{ props.item.province }}
                  span.separate &gt;&gt;
                  span(v-html="replaceBoldTag(props.item.zipcode, query.zipcode)") {{ props.item.zipcode }}
        .col-3.form-group
          label {{ $t('membership.address.tel') }}
          input.form-control(type='text', placeholder='', v-model="model.tel")
          small.form-text.text-muted
        .col-3.form-group
          label {{ $t('membership.address.fax') }}
          input.form-control(type='text', placeholder='', v-model="model.fax")
          small.form-text.text-muted
        .col-3.form-group
          label {{ $t('membership.address.mobile') }}
          the-maska-input.form-control(:mask="'###-###-####'", placeholder='', v-model="model.mobile")
          small.form-text.text-danger(v-show="!localError()['mobile'].numeric && localError()['mobile'].$dirty")
            | {{ $t('error.validation.integer', { name: $t('membership.address.mobile') } ) }}
          small.form-text.text-danger(v-show="!localError()['mobile'].mobile && localError()['mobile'].$dirty")
            | {{ $t('error.validation.mobile', { name: $t('membership.address.mobile') } ) }}
        .col-3.form-group
          label {{ $t('membership.address.email') }}
          input.form-control(type='text', placeholder='', v-model="model.email")
          small.form-text.text-danger(v-show="!localError()['email'].email && localError()['email'].$dirty")
            | {{ $t('error.validation.email', { name: $t('membership.address.email') } ) }}
</template>
<script>
import utils from '@/libs/utils'

export default {
  props: {
    value: {
      type: Object
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const localOptions = Object.assign({
      index: 0,
      key: 'addresses',
      showSameAddress: false
    }, this.options)
    return {
      sameAddress: false,
      localOptions,
      suggestionsOption: {
        inputClass: 'form-control',
        placeholder: ''
      },
      query: {
        subdistrict: '',
        district: '',
        province: '',
        zipcode: ''
      }
    }
  },
  computed: {
    model () {
      const model = this.value[this.localOptions.key][this.localOptions.index]
      return model || null
    },
    baseAddressSubdistrictId () {
      if (this.localOptions.baseAddressPrefix) {
        return this.model[`${this.localOptions.baseAddressPrefix}subdistrictId`]
      }
      return null
    },
    subdistrictId () {
      return this.model.subdistrictId
    }
  },
  watch: {
    baseAddressSubdistrictId () {
      if (this.sameAddress === true) {
        this.model.sameAddress = true
      }
    },
    model () {
      this.setupForm()
    },
    subdistrictId (value) {
      if (value) {
        this.displayAddressInput(value)
      } else {
        this.reset()
      }
    }
  },
  mounted () {
    this.setupForm()
  },
  methods: {
    setupForm () {
      if (this.model) {
        if (this.model.id) {
          this.sameAddress = false
        } else if (this.model.sameAddress !== null) {
          this.sameAddress = this.model.sameAddress
        }
        const subdistrictId = this.model[this.getField('subdistrictId')]
        if (subdistrictId) {
          this.displayAddressInput(subdistrictId)
        }
      }
    },
    getField (name) {
      return `${name}`
    },
    onSameAddressChange () {
      this.model.sameAddress = this.sameAddress
    },
    copyAddressValue () {
      const addressFields = utils.getAddressFields()
      addressFields.forEach(name => {
        this.model[`${name}`] =
          this.value[this.localOptions.baseAddressIndex][`${name}`]
        if (this.query[`${name}`] !== undefined) {
          this.query[`${name}`] = this.model[`${this.localOptions.baseAddressPrefix}${name}`].toString()
        }
      })
    },
    async onSubDisTrictInputChange (query) {
      if (query.length < 2) {
        return []
      }
      const response = await this.$services.location.findAllSubdistrict({
        per_page: 30
      }, query)
      const result = response.map(r => {
        return {
          id: r.id,
          subdistrict: r.name,
          subdistrictId: r.id,
          district: r.district.name,
          province: r.district.province.name,
          zipcode: r.zipcode
        }
      })
      return result
    },
    async onDisTrictInputChange (query) {
      if (query.length < 3) {
        return []
      }
      const response = await this.$services.location.findAllDistrict({}, query)
      const result = response.map(r => {
        return {
          district: r.name,
          province: r.province.name
        }
      })
      return result
    },
    async onZipcodeInputChange (query) {
      if (query.length < 2) {
        return []
      }
      const response = await this.$services.location.findAllZipcode({
        per_page: 30
      }, query)
      const result = response.map(r => {
        return {
          id: r.id,
          subdistrict: r.name,
          subdistrictId: r.id,
          district: r.district.name,
          province: r.district.province.name,
          zipcode: r.zipcode
        }
      })
      return result
    },
    onSelected (item) {
      if (item.subdistrict) {
        // this.model[this.getField('subdistrict')] = item.subdistrict
        this.model.subdistrict = item.name
        this.model[this.getField('subdistrictId')] = item.id
        this.query.subdistrict = item.subdistrict
      }
      if (item.district) {
        // this.model[this.getField('district')] = item.district
        this.query.district = item.district
      }
      if (item.province) {
        // this.model[this.getField('province')] = item.province
        this.query.province = item.province
      }
      if (item.zipcode) {
        // this.model[this.getField('zipcode')] = item.zipcode
        this.query.zipcode = item.zipcode.toString()
      }
    },
    replaceBoldTag (text, query) {
      text = text.toString()
      return text.replace(query, `<strong>${query}</strong>`)
    },
    localError () {
      return this.$v.addresses[this.localOptions.index]
    },
    displayAddressInput (id) {
      this.$services.location.findSubdistrict(id)
        .then(row => {
          this.query.subdistrict = row.name
          this.query.district = row.district.name
          this.query.province = row.district.province.name
          this.query.zipcode = row.zipcode.toString()
        })
    },
    reset () {
      this.query = {
        subdistrict: '',
        district: '',
        province: '',
        zipcode: ''
      }
      this.model[this.getField('subdistrictId')] = null
    }
  }
}
</script>
