<template lang='pug'>
div
  .d-flex
    div.w-100.mr-2
      date-selector(v-model="localDate", @input="onDateChange")
    the-maska-input.form-control.time-input(:mask="'##:##:##'", placeholder='00:00:00', v-model="time", @input="onTimeChange", @blur="onTimeInputBlur")
</template>
<script>
import DateSelector from './DateSelector.vue'
import util from '@/libs/utils'
import moment from 'moment'
export default {
  props: {
    value: {
      type: [Date, String],
      default: null
    }
  },
  components: {
    DateSelector
  },
  data () {
    return {
      time: '',
      localDate: (this.value) ? new Date(this.value) : null
    }
  },
  mounted () {
    this.time = (this.value) ? moment(this.value).format('HHmmss') : ''
  },
  methods: {
    onDateChange (value) {
      if (value == null) {
        this.time = 0
      }
      this.$emit('input', this.localDate)
    },
    onTimeChange (value) {
      if (this.localDate != null) {
        let hour = 0
        let min = 0
        let second = 0
        if (value.length === 6) {
          hour = parseInt(value[0] + value[1])
          min = parseInt(value[2] + value[3])
          second = parseInt(value[4] + value[5])
          if (hour < 0 || hour > 23) {
            hour = 0
          }
          if (min < 0 || min > 59) {
            min = 0
          }
          if (second < 0 || second > 59) {
            second = 0
          }
        }
        const date = new Date(this.localDate)
        date.setHours(hour)
        date.setMinutes(min)
        date.setSeconds(second)
        this.localDate = date
        this.$emit('input', this.localDate)
      } else {
        this.$emit('input', null)
      }
    },
    onTimeInputBlur () {
      const value = this.time
      let hour = 0
      let min = 0
      let second = 0
      if (value.length === 6) {
        hour = parseInt(value[0] + value[1])
        min = parseInt(value[2] + value[3])
        second = parseInt(value[4] + value[5])
        if (hour < 0 || hour > 23) {
          hour = 0
        }
        if (min < 0 || min > 59) {
          min = 0
        }
        if (second < 0 || second > 59) {
          second = 0
        }
      }
      const newTime = util.pad(hour, 2) + util.pad(min, 2) + util.pad(second, 2)
      if (newTime !== value) {
        this.time = newTime
      }
    }
  }
}
</script>

<style lang='scss'>
.time-input {
  width: 85px;
}
</style>
