export default {
  code: {},
  validation: {
    default: '%{name} %{type} ไม่ถูกต้อง',
    required: '{name} ต้องไม่เป็นค่าว่าง',
    integer: '{name} ต้องเป็นตัวเลข',
    validMembershipAmount: '{name} ต้องหารด้วย 100 ลงตัว',
    minLength: '{name} ต้องไม่น้อยกว่า {min} ตัว',
    maxLength: '{name} ต้องไม่มากกว่า {max} ตัว',
    email: '{name} รูปแบบไม่ถูกต้อง',
    mobile: '{name} ต้องยาว 10 ตัว',
    includeAtleastOneProperty: 'กรุณาป้อนข้อมูล หมู่บ้าน / อาคาร, หมู่ที่, ตรอก / ซอย, ถนน อย่างใดอย่างหนึ่ง',
    notZero: '{name} ต้องไม่เป็น 0',
    time: '{name} รูปแบบไม่ถูกต้อง',
    minValue: '{name} ต้องไม่น้อยกว่า {min}'
  }
}
