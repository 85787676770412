export default {
  common: {
    all: 'ทั้งหมด',
    add: 'เพิ่ม',
    edit: 'แก้ไข',
    delete: 'ลบ',
    choose: 'เลือก',
    change: 'เปลี่ยน',
    review: 'ตรวจสอบ',
    back: 'กลับ',
    cancel: 'ยกเลิก',
    ok: 'ตกลง',
    confirm: 'ยืนยัน',
    save: 'บันทึก',
    saveAs: 'บันทึกข้อมูลเป็น ...',
    saving: 'กำลังบันทึก',
    noData: 'ไม่มีข้อมูล',
    noResult: 'ไม่มีผลลัพธ์',
    setting: 'ตั้งค่า',
    print: 'พิมพ์',
    register: 'สมัครใหม่',
    searchBy: 'ค้นหาโดย',
    search: 'ค้นหา',
    sort: 'เรียงลำดับ',
    status: 'สถานะ',
    close: 'ปิด',
    start: 'เรื่ม',
    end: 'สิ้นสุด',
    done: 'เสร็จสิ้น',
    total: 'ทั้งหมด',
    item: 'รายการ',
    history: 'ประวัติ',
    name: 'ชื่อ',
    receipt: 'ใบเสร็จรับเงิน',
    message: 'ข้อความ',
    detail: 'รายละเอียด',
    yes: 'ใช่',
    no: 'ไม่ใช่',
    reason: 'เหตุผล',
    approve: 'อนุมัติ',
    command: 'คำสั่ง',
    from: 'จาก',
    to: 'ถึง',
    hide: 'ซ่อน',
    createdAt: 'สร้างเมื่อ',
    updatedAt: 'แก้ไขเมื่อ',
    date: 'วันที่',
    time: 'เวลา',
    since: 'ตั้งแต่',
    cannotExport: 'ไม่สามารถ Export ได้',
    startCreatedAt: 'สร้างเมื่อเริ่มต้น',
    endCreatedAt: 'สร้างเมื่อสิ้นสุด',
    pleaseSelectStartDateAndEndDateBeforeExport: 'กรุณาเลือก เริ่มต้น และ สิ้นสุด ของ {type} ก่อน export',
    pleaseSelectDateRange: 'กรุณาระบุช่วงเวลา',
    none: 'ไม่มี',
    paidAt: 'ชำระเมื่อ',
    duplicate: 'ทำซ้ำ'
  }
}
