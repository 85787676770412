export const STAFF_STATUS = {
  active: true,
  inactive: false
}

export const STAFF_ROLES = {
  staff: 'staff',
  supervisor: 'supervisor',
  admin: 'admin',
  accounting: 'accounting',
  registrar: 'registrar',
  area_agent_viewer: 'area_agent_viewer',
  branch_committee_viewer: 'branch_committee_viewer'
}

export const WAITING_MEMBER_STATUS = {
  approved: 'approved',
  waiting_ect: 'waiting_ect',
  waiting_review: 'waiting_review',
  rejected: 'rejected',
  request_more_document: 'request_more_document'
}

export const PAYMENT_RECEIPT_STATE = {
  draft: 'draft',
  review: 'review',
  paid: 'paid',
  approved: 'approved',
  rejected: 'rejected',
  cancel: 'cancel'
}
export const PAYMENT_RECEIPT_TYPE = {
  register: 'register',
  renew: 'renew'
}
export const ECT_STATUS = {
  succeed: 'succeed',
  waiting: 'waiting',
  queued: 'queued',
  failed: 'failed',
  error: 'error',
  not_ready: 'not_ready'
}

export const MEMBERSHIP_TYPE = {
  yearly: 'yearly',
  permanent: 'permanent'
}

export const MOBILE_AGENT_APPLICANT_STATES = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
  request_document: 'request_document',
  refund: 'refund',
  cancel: 'cancel'
}

export const ONLINE_CHANNEL_APPLICANT_STATES = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
  request_document: 'request_document',
  refund: 'refund',
  cancel: 'cancel'
}

export const ONLINE_CHANNEL_PAYMENT_STATUS = {
  pending: 'pending',
  completed: 'completed',
  expired: 'expired',
  canceled: 'canceled',
  failed: 'failed',
  refund: 'refund',
  request_refund: 'request_refund'
}

export const MEMBER_LEVEL_POSITION = {
  mp: 'mp',
  executiveDirector: 'executive_director',
  ptpTeam: 'ptp_team'
}

export const AGENT_PAYMENT_METHOD = {
  QR: 'QR',
  CASH: 'CASH'
}

export const PROFILE_STATE = {
  approved: 'approved',
  waiting_review: 'waiting_review',
  rejected: 'rejected',
  cancel: 'cancel'
}
/*
พรบ พรรคการเมืองกำหนดให้หลังวันที่ 29 มกราคม 2566 ค่าสมาชิกรายปี 20 ตลอดชีพ 200
ราคาเก่าสมาชิกรายปี 100 ตลอดชีพ 2000
*/
export const MEMBERSHIP_PRICES = {
  previous: {
    yearly: 100,
    permanent: 2000
  },
  current: {
    yearly: 20,
    permanent: 200
  }
}

export const NEW_ACT_OF_LEGISLATION = new Date('2023-01-28T17:00:00.000Z')
