export default {
  path: '/profile',
  component: () => import('@/layouts/auth'), // webpackChunkName app
  children: [
    {
      path: '',
      name: 'profile-index',
      component: () => import('@/views/profile/Index')
    }
  ]
}
