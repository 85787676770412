export default {
  path: '/report',
  component: () => import('@/layouts/setting'), // dashboard
  children: [
    {
      name: 'report-index',
      path: '',
      component: () => import('@/views/report/Index')
    }
  ]
}
