<template lang="pug">
  div
    .d-flex
      .flex-grow-1
        dropdown-select-service(ref="dropdown", v-model="model", :options="dropdownOptions", @input="dropdownChange", :disabled="disabled", :clearable="clearable")
</template>
<script>
import utils from '@/libs/utils'
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value,
      dropdownOptions: {
        service: this.$services.location.findAllProvince
      }
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  methods: {
    onInput (event) {
      let value = event.target.value
      value = utils.convert(value, this.localOption.type)
      this.model = value
      this.$emit('input', value)
    },
    dropdownChange (value) {
      this.model = value
      this.$emit('input', value)
    }
  }
}
</script>
