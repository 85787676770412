<template lang="pug">
  .loading
    i.fas.fa-circle-notch.fa-spin(:class="[color]")
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-secondary'
    }
  }
}
</script>
<style lang="scss" scoped>
.loading{
  text-align: center;
}
i {
  font-size: 20px;
}
</style>
