<template lang="pug">
  div
    el-date-picker(type="date",)
</template>
<script>
export default {
  props: {
    value: {
    }
  },
  data () {
  }
}
</script>
<style lang="scss" scoped>

</style>
