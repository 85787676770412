export default (http) => ({
  findAll (query) {
    const params = Object.assign({}, query)
    if (params.search) {
      const q = params.search
      params.or = [`mobileAgentAccount.firstName||cont||${q}`, `mobileAgentAccount.lastName||cont||${q}`]
      delete params.search
    }
    return http.get('mobile-agent-transfer-payment', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({}, query)
    return http.get(`mobile-agent-transfer-payment/${id}`, { params })
      .then(response => response.data)
  },
  create (model) {
    return http.post('mobile-agent-transfer-payment', model).then(response => response.data)
  },
  deleteApplicants (id, model) {
    return http.post(`mobile-agent-transfer-payment/${id}/delete-applicants`, model)
      .then(response => response.data)
  }
})
