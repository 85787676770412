<template lang="pug">
  div
    select.form-control(v-model="model", @input="$emit('input', $event.target.value)")
      option(selected='selected')
      option(v-for="(value, ine) in lists" :value="value") {{ value }}
</template>
<script>
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    }
  },
  data () {
    return {
      model: this.value,
      lists: {
        1: 'นาย',
        2: 'นางสาว',
        3: 'นาง',
        4: 'หม่อมราชวงศ์',
        5: 'หม่อมหลวง',
        6: 'พลเอก',
        7: 'พลโท',
        8: 'พลตรี',
        9: 'พันเอก',
        10: 'พันโท',
        11: 'พันตรี',
        12: 'ร้อยเอก',
        13: 'ร้อยโท',
        14: 'ร้อยตรี',
        15: 'ว่าที่ร้อยตรี',
        16: 'สิบเอก',
        17: 'สิบโท',
        18: 'สิบตรี',
        19: 'จ่าสิบเอก',
        20: 'จ่าสิบโท',
        21: 'จ่าสิบตรี',
        22: 'พลตำรวจเอก',
        23: 'พลตำรวจโท',
        24: 'พลตำรวจตรี',
        25: 'พันตำรวจเอก',
        26: 'พันตำรวจโท',
        27: 'พันตำรวจตรี',
        28: 'ร้อยตำรวจเอก',
        29: 'ร้อยตำรวจโท',
        30: 'ร้อยตำรวจตรี',
        31: 'ดาบตำรวจ',
        32: 'สิบตำรวจเอก',
        33: 'สิบตำรวจโท',
        34: 'สิบตำรวจตรี'
      }
    }
  },
  mounted () {
  }
}
</script>
