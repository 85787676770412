export default {
  name: 'พิมพ์บัตรสมาชิก',
  printOnCard: 'พิมพ์บัตรสมาชิก',
  printOnWhitePaper: 'ทดสอบ พิมพ์บนดาษขาว',
  saveAsFile: 'บันทึกเป็นไฟล์ pdf',
  saveAsFileTest: 'บันทึกเป็นไฟล์ pdf (ทดสอบ)',
  setupPrinter: 'วิธีตั้งค่า Printer',
  wasNotPrinted: 'ยังไม่พิมพ์',
  markAsPrinted: 'ทำเครื่องหมายว่าพิมพ์ไปแล้ว',
  markAsNotPrint: 'ทำเครื่องหมายว่ายังไม่พิมพ์',
  statusPrint: {
    name: 'สถานะการพิมพ์บัตร',
    printed: 'พิมพ์บัตรแล้ว',
    unPrinted: 'ไม่เคยพิมพ์บัตร',
    requestPrinted: 'ขอพิมพ์บัตรใหม่'
  }
}
