export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      per_page: 10,
      page: 1,
      sort: 'id,DESC'
    }, query)

    return http.get('member', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`member/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    const postData = this._clean(data)
    if (postData.memberDto.id) {
      return this.update(postData, postData.memberDto.id)
    } else {
      return this.create(postData)
    }
  },
  create (data) {
    return http.post(`member`, data)
      .then(response => response.data)
  },
  update (data, id) {
    return http.put(`member/${id}`, data)
      .then(response => response.data)
  },
  _clean (data) {
    const result = Object.assign({}, data)
    return result
  },
  createAddressIfNeeds (model) {
    if (model.addresses.length === 2) {
      model.addresses[1].sameAddress = false
    }

    // check second address is exists ?
    if (!model.addresses[0]) {
      model.addresses[0] = {
        sameAddress: false,
        addressType: 'permanent',
        homeNo: null,
        villageNo: null,
        villageName: null,
        alley: null,
        street: null,
        subdistrictId: null,
        tel: null,
        fax: null,
        mobile: null
      }
    }
    if (!model.addresses[1]) {
      model.addresses[1] = {
        sameAddress: true,
        addressType: 'contact',
        homeNo: null,
        villageNo: null,
        villageName: null,
        alley: null,
        street: null,
        subdistrictId: null,
        tel: null,
        fax: null,
        mobile: null
      }
    }

    return model
  }
})
