import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const DEFAULT_LANG = 'th'

const messages = {}

const requireComponent = require.context(
  `./`,
  true,
  /[\w-]\.js$/
)

requireComponent.keys().forEach((filePath) => {
  if (filePath === './index.js') {
    return
  }
  const regx = /^.\/(\w*)\/(\w*).js$/
  if (regx.test(filePath)) {
    const [, lang, fileName] = regx.exec(filePath)
    if (lang) {
      const componentConfig = requireComponent(filePath)
      if (!messages[lang]) {
        messages[lang] = {}
      }
      if (fileName === 'index') {
        const data = componentConfig.default || componentConfig
        messages[lang] = Object.assign({}, messages[lang], data)
      } else if (fileName) {
        messages[lang][fileName] = componentConfig.default || componentConfig
      }
    }
  }
})

// const messages = {
//   th: require('./i18n/th.json.js'),
//   en: require('./i18n/en.json.js')
// }
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages
})

export default i18n
