import provinceData from '@/data/province'
export const prefix = [
  { label: 'นาย', value: 'นาย' },
  { label: 'นางสาว', value: 'นางสาว' },
  { label: 'นาง', value: 'นาง' },
  { label: 'หม่อมราชวงศ์', value: 'หม่อมราชวงศ์' },
  { label: 'หม่อมหลวง', value: 'หม่อมหลวง' },
  { label: 'พลเอก', value: 'พลเอก' },
  { label: 'พลโท', value: 'พลโท' },
  { label: 'พลตรี', value: 'พลตรี' },
  { label: 'พันเอก', value: 'พันเอก' },
  { label: 'พันโท', value: 'พันโท' },
  { label: 'พันตรี', value: 'พันตรี' },
  { label: 'ร้อยเอก', value: 'ร้อยเอก' },
  { label: 'ร้อยโท', value: 'ร้อยโท' },
  { label: 'ร้อยตรี', value: 'ร้อยตรี' },
  { label: 'ว่าที่ร้อยตรี', value: 'ว่าที่ร้อยตรี' },
  { label: 'สิบเอก', value: 'สิบเอก' },
  { label: 'สิบโท', value: 'สิบโท' },
  { label: 'สิบตรี', value: 'สิบตรี' },
  { label: 'จ่าสิบเอก', value: 'จ่าสิบเอก' },
  { label: 'จ่าสิบโท', value: 'จ่าสิบโท' },
  { label: 'จ่าสิบตรี', value: 'จ่าสิบตรี' },
  { label: 'พลตำรวจเอก', value: 'พลตำรวจเอก' },
  { label: 'พลตำรวจโท', value: 'พลตำรวจโท' },
  { label: 'พลตำรวจตรี', value: 'พลตำรวจตรี' },
  { label: 'พันตำรวจเอก', value: 'พันตำรวจเอก' },
  { label: 'พันตำรวจโท', value: 'พันตำรวจโท' },
  { label: 'พันตำรวจตรี', value: 'พันตำรวจตรี' },
  { label: 'ร้อยตำรวจเอก', value: 'ร้อยตำรวจเอก' },
  { label: 'ร้อยตำรวจโท', value: 'ร้อยตำรวจโท' },
  { label: 'ร้อยตำรวจตรี', value: 'ร้อยตำรวจตรี' },
  { label: 'ดาบตำรวจ', value: 'ดาบตำรวจ' },
  { label: 'สิบตำรวจเอก', value: 'สิบตำรวจเอก' },
  { label: 'สิบตำรวจโท', value: 'สิบตำรวจโท' },
  { label: 'สิบตำรวจตรี', value: 'สิบตำรวจตรี' }
]
export const membershipType = [
  // { label: 'รายปี 50 บาท', value: 'permanent' },
  { label: 'รายปี', value: 'yearly' },
  { label: 'ตลอดชีพ', value: 'permanent' }
]

export const single = [
  { label: 'โสด', value: 'โสด' },
  { label: 'สมรส', value: 'สมรส' },
  { label: 'หย่า', value: 'หย่า' },
  { label: 'หม้าย', value: 'หม้าย' }
]

export const nationality = [
  { label: 'ไทย', value: 'ไทย' }
]
export const race = [
  { label: 'ไทย', value: 'ไทย' }
]
export const religion = [
  { label: 'พุทธ', value: 'พุทธ' },
  { label: 'คริสต์', value: 'คริสต์' },
  { label: 'อิสลาม', value: 'อิสลาม' },
  { label: 'ฮินดู', value: 'ฮินดู' }
]

export const education = [
  { label: 'ตำ่กว่าประถมศึกษา', value: 'below_elementary' },
  { label: 'ประถมศึกษา', value: 'elementary' },
  { label: 'มัธยมศึกษา', value: 'high_school' },
  { label: 'ปริญญาตรี', value: 'bachelor' },
  { label: 'ปริญญาโท', value: 'master' },
  { label: 'ปริญญาเอก', value: 'above_master' }
]

export const membershipProposes = [
  { label: 'ลงสมัครรับเลือกตั้ง', value: 'election' },
  { label: 'มีส่วนกำหนดนโยบาย', value: 'policy' },
  { label: 'ให้การสนับสนุนพรรค', value: 'support' },
  { label: 'ร่วมกิจกรรมพรรค', value: 'activity' }
]

export const helpParty = [
  { label: 'ด้านประชุม อบรม สัมนา', value: 'meeting' },
  { label: 'ลงพื้นที่ แจกใบปลืว เผยแพร่สื่อ', value: 'pr' },
  { label: 'ด้านคอมพิวเตอร์ สื่อสารออนไลน์ ต่างๆ', value: 'online' },
  { label: 'อื่นๆ โปรด ระบุ', value: 'other', inputs: [{ name: 'other', label: 'ระบุ' }] }
]

export const provinces = provinceData

export const membershipStatus = [
  { label: 'แบบร่าง', value: 'draft' },
  { label: 'รอการตรวจสอบ', value: 'review' },
  { label: 'ลาออก', value: 'resigned' },
  { label: 'ขาดคุณสมบัติ', value: 'rejected' },
  { label: 'เป็นสมาชิกพรรคสมบูรณ์', value: 'approved' },
  { label: 'เสียชีวิต', value: 'death' },
  { label: 'ยุติการเป็นสมาชิก', value: 'terminated' },
  { label: 'พ้นสภาพ', value: 'obsolete' }
]

export const gender = [
  { label: 'ชาย', value: 'ชาย' },
  { label: 'หญิง', value: 'หญิง' }
]

export default {
  prefix,
  membershipType,
  single,
  nationality,
  race,
  religion,
  education,
  membershipProposes,
  helpParty,
  provinces,
  membershipStatus,
  gender
}
