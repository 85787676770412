const state = {
  themeMode: {
    dark: false,
    light: true,
    semi_dark: false,
    theme_color: 'lite-ptp',
    layout: 'large-sidebar',
    rtl: false,
    footer: true
  }
}

const getters = {
  getThemeMode: state => state.themeMode
}

const actions = {
  changeThemeMode ({ commit }) {
    commit('toggleThemeMode')
  },
  changeThemeLayout ({ commit }, data) {
    commit('toggleThemeLayout', data)
  },
  changeThemeRtl ({ commit }) {
    commit('toggleThemeRtl')
  },
  showFooter ({ commit }, isShow) {
    commit('showFooter', isShow)
  }
}

const mutations = {
  toggleThemeMode: state => {
    state.themeMode.dark = !state.themeMode.dark
  },
  toggleThemeLayout (state, data) {
    state.themeMode.layout = data
  },
  toggleThemeRtl (state) {
    state.themeMode.rtl = !state.themeMode.rtl
  },
  showFooter (state, show) {
    state.themeMode.footer = show
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
