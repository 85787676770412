export default (http) => ({
  findAll (query) {
    const params = Object.assign({}, query)
    if (params.search) {
      const q = params.search
      params.keyword = q
      delete params.search
    }
    return http.get('online-channel-payment', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({}, query)
    return http.get(`online-channel-payment/${id}`, { params })
      .then(response => response.data)
  },
  save (id, model) {
    if (id) {
      return this.update(id, model)
    } else {
      return this.create(model)
    }
  },
  create (model) {
    return http.post(`online-channel-payment`, model)
      .then(response => response.data)
  },
  update (id, model) {
    return http.put(`online-channel-payment/${id}`, model)
      .then(response => response.data)
  },
  approve (id, model) {
    return http.post(`online-channel-payment/approve/${id}`, model)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`online-channel-payment/${id}`)
      .then(response => response.data)
  }
})
