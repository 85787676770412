<template lang="pug">
div
  .breadcrumb.d-flex.justify-content-between
    .d-flex.align-items-center
      div.mr-0
        slot(v-if="$scopedSlots.header", name="header", v-bind:header="header")
        h1(v-else) {{ header }}
      ul
        //- li
        //-   router-link(:to="{ path: '/' }") หน้าแรก
        li(v-for="(page, i) in pages", :key="i")
          router-link(v-if="i !== pages.length - 1", :to="page.to") {{ page.label }}
          span(v-else)  {{ page.label }}
    .mt-2.mt-md-0
      slot(name='action')
  .separator-breadcrumb.border-top
</template>
<script>
export default {
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    header: {
      type: String,
      default: null
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  margin-bottom: 0.75rem;
  font-size: 16px;
  ul li {
  }
}
.separator-breadcrumb {
  margin-bottom: 1.25rem;
}
</style>
