<template lang="pug">
form.d-flex.align-items-center.mt-3(@submit.prevent="onSubmit")
  .mr-2 จำนวนทั้งหมด {{ totalItem }} รายการ
  ul.pagination(v-if="totalPage > 1")
    li.page-item.first(:class="{ 'disabled': page <= 1 }")
      router-link.page-link(:to='getUrl(firstPage)')
        i.fas.fa-angle-double-left
    li.page-item.prev(:class="{ 'disabled': page <= 1 }")
      router-link.page-link(:to='getUrl(page - 1)')
        i.fas.fa-angle-left
    li.page-item(v-for="index in buttons" :key="index" :class="{ 'active': page == index }")
      router-link.page-link(:to='getUrl(index)') {{ index }}
    li.page-item.next(:class="{ 'disabled': page >= totalPage }")
      router-link.page-link(:to='getUrl(page + 1)')
        i.fas.fa-angle-right
    li.page-item.last(:class="{ 'disabled': page >= totalPage }")
      router-link.page-link(:to='getUrl(totalPage)')
        i.fas.fa-angle-double-right
  .d-flex.align-items-center.ml-2.mb-2.mb-md-0(v-if="totalPage > 1")
    label.mb-0 {{ $t('pagination.gotoPage') }}
    input.form-control.ml-2(type="number", v-model="numberPage", style="width: 70px;")
  .d-flex.align-items-center.ml-2.mb-2.mb-md-0(v-if="totalPage > 0 && pageSize")
    label.mb-0 {{ $t('pagination.itemSize') }}
    select.custom-select.ml-1(v-model="internalPageSize", style="width: 70px;", @input="sizeChange")
      option(v-for="size in pageSizes", :value="size", :key="size") {{ size }}
</template>
<script>
export default {
  props: {
    totalPage: {
      type: Number,
      default: 0
    },
    numberOfButton: {
      type: Number,
      default: 7
    },
    route: {
      type: Object
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30]
    },
    pageSize: {
      type: Number,
      default: null
    },
    totalItem: {
      type: Number,
      required: true
    }
  },
  computed: {
    page () {
      return parseInt(this.$route.query && this.$route.query.page) || 1
    }
  },
  created () {
    this.setup()
  },
  watch: {
    page (value) {
      this.setup()
    },
    totalPage (value) {
      this.setup()
    },
    pageSize (value) {
      this.internalPageSize = value
    }
  },
  data () {
    return {
      firstPage: 1,
      buttons: [],
      numberPage: '',
      internalPageSize: this.pageSize
    }
  },
  methods: {
    setup () {
      this.buttons = []
      this.numberPage = this.page
      let start = Math.max(1, (this.page) - Math.floor(this.numberOfButton / 2))
      var end = Math.min(this.totalPage, start + this.numberOfButton - 1)
      if (end - start < this.numberOfButton) {
        start = Math.max(1, start - (this.numberOfButton - (end - start) - 1))
      }
      for (var i = start; i <= end; i++) {
        this.buttons.push(i)
      }
    },
    gotoPage (page) {
      this.$emit('change', page)
    },
    getUrl (page) {
      const route = Object.assign({}, this.route || this.$route)
      const options = { page }
      route.params = Object.assign({}, route.params, options)
      route.query = Object.assign({}, route.query, options)
      return { path: route.path, name: route.name, params: route.params, query: route.query }
    },
    onSubmit () {
      if (this.numberPage > 0 && this.numberPage <= this.totalPage) {
        this.$router.push(this.getUrl(this.numberPage))
      }
    },
    sizeChange (e) {
      this.$emit('size-change', parseInt(e.target.value))
    }
  }
}
</script>

<style lang="scss">
.pagination {
  margin: 0px 0 0 0;
}
</style>
