<template lang="pug">
  modal-wrapper(size="modal-md", ref="modal")
    .modal-header
      h4.modal-title {{ title }}
      button.close(type='button', @click="hide")
        span(aria-hidden='true') &times;
    .modal-body
      div {{ message }}
    .modal-footer
      button.btn.btn-secondary(type="button" @click="onCancelClick") {{ noText }}
      button.btn.btn-primary(type="button" :class="{ 'is-loading': loading }" @click="onOkClick") {{ yesText }}
</template>
<script>
export default {
  data () {
    return {
      title: 'title',
      loading: false,
      message: 'test message',
      noText: this.$t('common.cancel'),
      yesText: this.$t('common.ok'),
      onOk: () => {},
      onCancel: () => {}
    }
  },
  methods: {
    show ({ title, message, onOk, onCancel, yesText, noText }) {
      this.title = title
      this.message = message
      if (yesText) {
        this.yesText = yesText
      }
      if (noText) {
        this.noText = noText
      }
      if (onCancel) {
        this.onCancel = onCancel
      }
      this.onOk = onOk
      this.$refs.modal.show()
    },
    hide () {
      if (this.loading) {
        return
      }
      this.$refs.modal.hide()
    },
    onOkClick () {
      if (this.onOk) {
        this.onOk()
      }
      this.hide()
    },
    onCancelClick () {
      if (this.onCancel) {
        this.onCancel()
      }
      this.hide()
    }
  }
}
</script>
