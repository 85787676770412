<template lang="pug">
  div
    .d-flex
      .flex-grow-1
        dropdown-select-service(ref="dropdown", v-model="model", :options="dropdownOptions", @input="dropdownChange", :disabled="disabled")
</template>
<script>
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    provinceId: {
      type: Number,
      default: null
    }
  },
  data () {
    const localOption = Object.assign({
      type: 'String'
    }, this.options)
    return {
      localOption,
      model: this.value,
      dropdownOptions: {
        service: this.getService
      }
    }
  },
  watch: {
    value (val) {
      this.model = val
    },
    provinceId (id) {
      this.$emit('input', null)
      this.dropdownOptions = {
        service: this.getService
      }
    }
  },
  methods: {
    dropdownChange (value) {
      this.model = value
      this.$emit('input', value)
    },
    getService () {
      if (!this.provinceId) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      return this.$services.location.findAllDistrict({
        per_page: 99,
        page: 1,
        filter: `provinceId||eq||${this.provinceId}`
      })
        .then((response) => {
          return response.data
        })
    }
  }
}
</script>
