<template lang="pug">

  div
    input-loading(v-if="loading")
    .d-flex.justify-content-between(v-if="!loading")
      .flex-grow-1
        v-select(:reduce="tag => tag.value", v-model="model", :options="lists", @input="onInput", :disabled="disabled", :searchable="searchable", :clearable="clearable", :selectable="selectable")
      //- button.btn.btn-primary.btn-sm.ml-3
      //-   i.fas.fa-plus
    //- select.custom-select(v-model="model", @input="")
    //-   option(selected='selected')
    //-   option(v-for="(value, i) in options.data" :value="i") {{ value }}
</template>
<script>
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Function
    }
  },
  data () {
    return {
      loading: true,
      lists: [],
      localOptions: Object.assign({
        service: null,
        labelKey: 'name',
        valueKey: 'id'
      }, this.options),
      model: this.value
    }
  },
  computed: {
  },
  watch: {
    value (value) {
      this.model = value
    },
    options (newVal) {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    onInput (item) {
      let value = item
      if (item && typeof item === 'object') {
        value = item.value
      }
      this.$emit('input', value)
      this.$emit('next', this)
    },
    loadData () {
      this.loading = true
      this.localOptions.service()
        .then(rows => {
          this.$emit('loaded', rows)
          this.lists = rows.map(row => {
            return { label: row[this.localOptions.labelKey], value: row[this.localOptions.valueKey] }
          })
          this.loading = false
        })
    }
  }
}
</script>
