export default {
  path: '/mobile-agent',
  component: () => import('@/layouts/default'), // dashboard
  children: [
    {
      name: 'mobile-agent-account-index',
      path: 'account',
      component: () => import('@/views/mobileAgent/account/Index')
    },
    {
      name: 'mobile-agent-account-create',
      path: 'account/create',
      component: () => import('@/views/mobileAgent/account/Create')
    },
    {
      name: 'mobile-agent-account-update',
      path: 'account/:id',
      component: () => import('@/views/mobileAgent/account/Update')
    },
    {
      name: 'mobile-agent-applicant-index',
      path: 'applicant',
      component: () => import('@/views/mobileAgent/applicant/Index')
    },
    {
      name: 'mobile-agent-applicant-review',
      path: 'applicant/:id',
      component: () => import('@/views/mobileAgent/applicant/Review')
    },
    {
      name: 'mobile-agent-transfer-payment-index',
      path: 'transfer-payment',
      component: () => import('@/views/mobileAgent/transferPayment/Index')
    },
    {
      name: 'mobile-agent-transfer-payment-create',
      path: 'transfer-payment/create',
      component: () => import('@/views/mobileAgent/transferPayment/Create')
    },
    {
      name: 'mobile-agent-transfer-payment-view',
      path: 'transfer-payment/view/:id',
      component: () => import('@/views/mobileAgent/transferPayment/View')
    },
    {
      name: 'mobile-agent-request-refund',
      path: 'request-refund',
      component: () => import('@/views/mobileAgent/applicant/RequestRefund')
    }
  ]
}
