import axios from 'axios'
// import Vue from 'vue'
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000
})

instance.interceptors.request.use(function (config) {
  const userInfoRaw = localStorage.getItem('userInfo')
  if (userInfoRaw) {
    const userInfo = JSON.parse(userInfoRaw)
    config.headers['authorization'] = `Bearer ${userInfo.accessToken}`
  }

  return config
}, function (error) {
  if (error.response) {
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error)
})

export default instance
