export default [
  {
    name: 'error-403',
    path: '/error/403',
    component: () => import('@/views/error/403')
  },
  {
    path: '*',
    component: () => import('@/views/page/NotFound')
  }
]
