<template lang="pug">
div
  //- p {{ appliedDate }}
  v-select(
  :reduce="d => d.value",
  v-model="model",
  :options="memberShipTypeList",
  @input="onInput",
  :disabled="disabled"
  )
</template>
<script>
import membershipHelper from '@/libs/membershipHelper'
export default {
  props: {
    value: {
      Type: String
    },
    $v: {
      type: Object
    },
    disabled: {
      default: false
    },
    appliedDate: {
      type: [Date, String]
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    memberShipTypeList () {
      let yearlyLabel = 'รายปี'
      let permanentLabel = 'ตลอดชีพ'
      if (this.appliedDate) {
        const yearly = membershipHelper.calculateReceiptAmount('yearly', this.appliedDate)
        const permanent = membershipHelper.calculateReceiptAmount('permanent', this.appliedDate)
        yearlyLabel = `รายปี ${yearly} บาท`
        permanentLabel = `ตลอดชีพ ${permanent} บาท`
      }
      return [
        { label: yearlyLabel, value: 'yearly' },
        { label: permanentLabel, value: 'permanent' }
      ]
    }
  },
  watch: {
    value (val) {
      this.model = val
    }
  },
  mounted () {
  },
  methods: {
    onInput (item) {
      let value = item

      if (item && typeof item === 'object') {
        value = item.value
      }
      this.$emit('input', value)
      this.$emit('next', this)
    }
  }
}
</script>
