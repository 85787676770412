
const baseName = 'online-channel'

export default {
  path: `/${baseName}-applicant`,
  component: () => import('@/layouts/default'), // dashboard
  children: [
    {
      name: `${baseName}-applicant-index`,
      path: 'applicant',
      component: () => import('@/views/onlineChannel/applicant/Index')
    },
    {
      name: `${baseName}-applicant-review`,
      path: 'applicant/:id',
      component: () => import('@/views/onlineChannel/applicant/Review')
    },
    {
      name: `${baseName}-payment-index`,
      path: 'payment',
      component: () => import('@/views/onlineChannel/payment/Index')
    },
    {
      name: `${baseName}-payment-create`,
      path: 'payment/create',
      component: () => import('@/views/onlineChannel/payment/Create')
    },
    {
      name: `${baseName}-payment-update`,
      path: 'payment/:id',
      component: () => import('@/views/onlineChannel/payment/Update')
    }
  ]
}
