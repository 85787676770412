export default (http) => ({
  findAll (query, q) {
    let params = Object.assign({
      per_page: 100
    }, query)
    if (q) {
      params.filter = 'name||cont||' + q
    }
    return http.get('member-group', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  find (id, query = {}) {
    const params = Object.assign({}, query)

    return http.get(`member-group/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`member-group`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`member-group/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`member-group/${id}`)
      .then(response => response.data)
  }
})
