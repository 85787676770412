<template lang="pug">
  div
    div.mb-4(v-for="(receipt, i) in receipts")
      h6
        strong ใบเสร็จใบที่ {{ i + 1 }}
      .row
        //- .col-12
          //- pre {{ $v.$each.$iter }}
        //-   pre {{ $v.paymentReceipts[i] }}
        .col-4.col
          .form-group
            label
              span.mr-1 {{ $t('membership.form.bookNo') }}
              span.text-danger(v-if="$v.$each.$iter[i].bookNo.$params.required") *
            text-input(placeholder='', v-model='receipt.bookNo', :options="{ type: 'String' }", :disabled="isDisabled(i)")
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].bookNo.required && $v.$each.$iter[i].bookNo.$dirty")
              | {{ $t('error.validation.required', { name: $t('membership.form.bookNo') } ) }}
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].bookNo.integer && $v.$each.$iter[i].bookNo.$dirty")
              | {{ $t('error.validation.integer', { name: $t('membership.form.bookNo') } ) }}
        .col-4.col
          .form-group
            label
              span.mr-1 {{ $t('membership.form.receiptNo') }}
              span.text-danger(v-if="$v.$each.$iter[i].receiptNo.$params.required") *
            text-input(type='text', placeholder='', v-model='receipt.receiptNo', :options="{ type: 'String' }", :disabled="isDisabled(i)")
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].receiptNo.required && $v.$each.$iter[i].receiptNo.$dirty")
              | {{ $t('error.validation.required', { name: $t('membership.form.receiptNo') } ) }}
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].receiptNo.integer && $v.$each.$iter[i].receiptNo.$dirty")
              | {{ $t('error.validation.integer', { name: $t('membership.form.receiptNo') } ) }}
        .col-4.col
          .form-group
            label
              span.mr-1 {{ $t('membership.form.amount') }}
              span.text-danger(v-if="$v.$each.$iter[i].amount.$params.required") *
            text-input(type='text', placeholder='', v-model='receipt.amount', :options="{ type: 'String' }", :disabled="isDisabled(i)")
            //- small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].amount.required && $v.$each.$iter[i].amount.$dirty")
            //-   | {{ $t('error.validation.required', { name: $t('membership.form.amount') } ) }}
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].amount.integer && $v.$each.$iter[i].amount.$dirty")
              | {{ $t('error.validation.integer', { name: $t('membership.form.amount') } ) }}
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].amount.validAmount && $v.$each.$iter[i].amount.$dirty")
              | {{ $t('error.validation.validMembershipAmount', { name: $t('membership.form.amount') } ) }}
        .col-6.col
          .form-group
            label
              span.mr-1 {{ $t('membership.form.receiptDate') }}
              span.text-danger(v-if="$v.$each.$iter[i].receiptDate.$params.required") *
            date-selector(type='text', placeholder='', v-model='receipt.receiptDate', :disabled="isDisabled(i)")
            small.form-text.error.text-danger(v-show="!$v.$each.$iter[i].receiptDate.required && $v.$each.$iter[i].receiptDate.$dirty")
              | {{ $t('error.validation.required', { name: $t('membership.form.receiptDate') } ) }}
        .col-6.col
          .form-group
            label {{ $t('membership.form.endDate') }}
            date-selector(type='text', placeholder='', v-model='receipt.endDate', :disabled="isDisabled(i)")
    //- .row.mt-2
    //-   .col-12
    //-     button.btn.btn-info(type="button", @click.prevent="addNewReceipt") เพิ่มใบเสร็จ
</template>
<script>
import utils from '@/libs/utils'
export default {
  props: {
    value: {
      Type: Array
    },
    $v: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  data () {
    const localOption = Object.assign({
    }, this.options)
    return {
      localOption
    }
  },
  computed: {
    receipts () {
      return this.value
    }
  },
  mounted () {
    if (this.value.length === 0) {
      this.addNewReceipt()
    }
    this.updateFormat()
  },
  methods: {
    addNewReceipt () {
      // eslint-disable-next-line vue/no-mutating-props
      this.value.push({
        bookNo: null,
        receiptNo: null,
        receiptDate: null,
        amount: null,
        endDate: null
      })
    },
    updateFormat () {
      this.receipts.forEach(receipt => {
        if (receipt.bookNo) {
          this.$set(receipt, 'bookNo', utils.pad(receipt.bookNo, 4))
        }
        if (receipt.receiptNo) {
          this.$set(receipt, 'receiptNo', utils.pad(receipt.receiptNo, 6))
        }
      })
    },
    isDisabled (index) {
      const total = this.receipts.length
      return index < total - 1
    }

  }
}
</script>
