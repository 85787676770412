// import http from '@/libs/http'

export default (http) => ({
  signin (data) {
    return http.post('auth/signin', data)
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  setAuthorizeHeader (token) {
    http.defaults.headers.common['authorization'] = `Bearer ${token}`
  }
})
