function updateInvalidStyle (el, binding) {
  const selectElements = el.getElementsByTagName('select')
  const inputElements = el.getElementsByTagName('input')
  const allElements = [...selectElements, ...inputElements]
  if (binding.value) {
    el.classList.add('is-invalid')
    for (const element of allElements) {
      element.classList.add('is-invalid')
    }
  } else {
    el.classList.remove('is-invalid')
    for (const element of allElements) {
      element.classList.remove('is-invalid')
    }
  }
}

export default {
  bind: function (el, binding, vnode) {
    updateInvalidStyle(el, binding)
  },
  update: function (el, binding, vnode) {
    updateInvalidStyle(el, binding)
  }
}
