export default {
  name: 'สมาชิกพรรค',
  add: 'เพิ่มข้อมูลสมาชิกพรรค',
  all: 'สมาชิกพรรคทั้งหมด',
  reviewMembership: 'สมาชิกที่รอการตรวจสอบ',
  form: {
    name: 'ชื่อ',
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    birthDate: 'วันเกิด',
    state: 'สถานะ',
    autofill: 'กรอกข้อมูลอัตโนมัติ',
    nationality: 'สัญชาติ',
    race: 'เชื้อชาติ',
    religion: 'ศาสนา',
    idCard: 'บัตรประจำตัวประชาชน',
    idCardShort: 'id card',
    partyId: 'รหัสสมาชิก',
    idIssueDate: 'วันออกบัตร',
    idExpireDate: 'วันหมดอายุ',
    marriedStatus: 'สถานภาพ',
    birthProvinceId: 'จังหวัดที่เกิด',
    registerPlace: 'สถานที่ยื่นใบสมัคร',
    membershipType: 'ระยะเวลาสมาชิก',
    address: 'ที่อยู่ตามทะเบียนบ้าน',
    agentId: 'ผู้หาสมาชิก',
    appliedAt: 'รับเป็นสมาชิก',
    receivedAndAppliedAt: 'ยื่นใบสมัคร / รับเป็นสมาชิก',
    expiredAt: 'วันที่หมดอายุการเป็นสมาชิก',
    bookNo: 'เล่มที่',
    receiptNo: 'เลขที่',
    receiptDate: 'วันที่',
    amount: 'จำนวนเงิน',
    endDate: 'สิ้นสุด',
    successCreatedTitle: 'บันทึกข้อมูลสมาชิกเรียบร้อย',
    sendToReview: 'ส่งไปตรวจ',
    approved: 'อนุมัติ',
    backToDraft: 'กลับสู่แบบร่าง',
    reject: 'ไม่ผ่านการตรวจสอบ',
    resigned: 'ลาออก',
    terminated: 'ยุติการเป็นสมาชิก',
    obsolete: 'พ้นสภาพการเป็นสมาชิก',
    death: 'เสียชีวิต',
    resignedAt: 'ออกเมื่อวันที่',
    printedAt: 'พิมพ์เมื่อวันที่',
    rejectReason: 'แจ้งเตือน',
    newRegister: 'สมัครใหม่',
    useThisData: 'ใช้ข้อมูลนี้',
    memberAt: 'เป็นสมาชิก',
    gender: 'เพศ',
    sendToCreateReceipt: 'ส่งไปออกใบเสร็จ',
    cannotToCreateReceipt: 'ไม่สามารถส่งไปออกใบเสร็จได้',
    ectRequestedAt: 'วันที่ส่งข้อมูลไป กกต.',
    registrarApprovedAt: 'นายทะเบียนรับรองเมื่อ',
    registrarApproved: 'นายทะเบียนรับรอง',
    printedApplicantAt: 'วันที่พิมพ์ใบสมัคร',
    printCount: 'พิมพ์ครั้งที่',
    editPrintCount: 'แก้ไขจำนวนครั้งที่พิมพ์',
    toMembership: 'ไปยังสมาชิก'
  },
  address: {
    homeNo: 'บ้านเลขที่',
    villageName: 'หมู่บ้าน / อาคาร',
    villageNo: 'หมู่ที่',
    alley: 'ตรอก / ซอย',
    street: 'ถนน',
    subdistrict: 'ตำบล / แขวง',
    district: 'อำเภอ / เขต',
    province: 'จังหวัด',
    zipcode: 'รหัสไปรษณีย์',
    tel: 'โทรศัพท์',
    fax: 'โทรสาร',
    mobile: 'มือถือ',
    email: 'อีเมล'
  },
  autofill: {
    name: 'กรอกข้อมูลอัตโนมัติ',
    modalTitle: 'ตั้งค่าการกรอกข้อมูลอัตโนมัติ',
    saveAndRefresh: 'บันทึกและอัพเดทฟอร์ม',
    homeSubdistrict: 'ที่อยู่ตามทะเบียนบ้าน',
    currentSubdistrict: 'ที่อยู่ปัจจุบัน'
  },
  reject: {
    title: 'ใส่เหตุผลในการ',
    message: 'ข้อความ',
    date: 'วันที่',
    draft: 'ไม่ผ่านการตรวจสอบหรือกลับสู่แบบร่าง',
    resigned: 'ลาออก',
    terminated: 'ยุติการเป็นสมาชิก',
    death: 'เสียชีวิต'
  },
  renew: {
    name: 'ต่ออายุสมาชิก',
    completed: 'ต่ออายุสำเร็จ',
    renewReceiptCompleted: 'ส่งไปออกใบเสร็จรับเงินเรียบร้อยแล้ว'
  },
  status: {
    expired: 'หมดอายุการเป็นสมาชิก'
  },
  history: {
    renewAt: 'ต่ออายุปีที่',
    debt: 'ค้างค่าชำระปีที่',
    noRenew: 'ยังไม่ได้ต่ออายุ'
  },
  ect: {
    name: 'สถานะสมาชิกระบบ กกต.',
    reason: 'เหตุผล',
    requestedAt: 'วันที่ส่งข้อมูล',
    sentToECT: 'ส่งข้อมูลไป กกต.',
    backToNotReady: 'ย้อนกลับเป็น ไม่พร้อมส่ง',
    status: {
      succeed: 'สำเร็จ',
      waiting: 'รอการส่ง',
      queued: 'กำลังส่งข้อมูล',
      failed: 'ล้มเหลว',
      error: 'มีข้อผิดพลาด',
      not_ready: 'ยังไม่พร้อมส่ง'
    },
    dialog: {
      title: 'ยืนยันการส่งไประบบ กกต.',
      message:
        'คุณต้องการตั้งค่าให้สมาชิกท่านนี้เป็นพร้อมส่งไประบบ กกต. เลยหรือไม่'
    }
  },
  dashboard: {
    name: 'แผงควบคุมสมาชิกพรรค',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    stateBox: {
      title: 'แบบร่างและรอการตรวจสอบทั้งหมด',
      draft: 'แบบร่าง',
      review: 'รอการตรวจสอบ'
    },
    recent: {
      register: 'ใบเสร็จที่สมัครเข้ามา 30 วันล่าสุด',
      renew: 'ใบเสร็จที่ต่ออายุเข้ามา 30 วันล่าสุด'
    },
    statistic: {
      title: 'สถิติสมาชิกพรรค',
      overAll: 'จำนวนสมาชิกทั้งประเทศ',
      selectTimeRange: 'เลือกระยะเวลา',
      allTime: 'ตลอดการสมัคร',
      map: {
        active: 'คงเหลือทั้งหมด',
        needRenew: 'ต้องต่ออายุ',
        terminated: 'ยุติ'
      },
      status: {
        active: 'สมาชิกคงเหลือ',
        needRenew: 'สมาชิกที่ต้องต่ออายุ',
        obsolete: 'พ้นสภาพ',
        resigned: 'ลาออก',
        death: 'เสียชีวิต',
        terminated: 'ยุติ'
      }
    },
    mode: {
      all: 'ทั้งประเทศ',
      province: 'จังหวัด',
      area: 'เขต'
    }
  },
  dialog: {
    title: 'ยืนยันการเป็นสมาชิก',
    message: 'คุณต้องการอนุมัติสมาชิกท่านนี้เลยหรือไม่'
  },
  type: {
    yearly: 'รายปี',
    permanent: 'ตลอดชีพ'
  },
  messageMergeMembership: 'สมาชิกเคยเข้าสู่ระบบออนไลน์แล้ว',
  registrarApprove: 'นายทะเบียนรับรองสมาชิก',
  registrarHasApproved: 'รับรองโดยนายทะเบียนแล้ว',
  source: {
    'mobile-agent': 'ช่องทางตัวแทน',
    'online-channel': 'ช่องทางออนไลน์'
  },
  printApplicant: {
    moduleName: 'พิมพ์ใบสมัครที่รับรองแล้ว',
    status: 'สถานะการพิมพ์',
    saveAsFile: 'บันทึกเป็นไฟล์ pdf',
    wasPrinted: 'พิมพ์แล้ว',
    wasNotPrinted: 'ยังไม่พิมพ์',
    markAsPrinted: 'ทำเครื่องหมายว่าพิมพ์ไปแล้ว',
    markAsNotPrint: 'ทำเครื่องหมายว่ายังไม่พิมพ์',
    saveAsFileFailed: 'ไม่สามารถบันทึกไฟล์ pdf ได้ กรุณาลองใหม่อีกครั้ง',
    markAsPrintedFailed: 'ไม่สามารถทำเครื่องหมายว่าพิมพ์ไปแล้วได้ กรุณาลองใหม่อีกครั้ง',
    markAsNotPrintFailed: 'ไม่สามารถทำเครื่องหมายว่ายังไม่พิมพ์ได้ กรุณาลองใหม่อีกครั้ง',
    selectedAllApplicants: 'เลือกใบสมัครทั้งหมด {count} รายการ ในหน้านี้แล้ว',
    selectedAllPages: 'เลือกใบสมัครทั้งหมด {count} รายการ',
    clearSelected: 'ล้างที่เลือก',
    overPrintLimit: 'ไม่สามารถทำรายการเกิน {count} รายการได้'
  },
  document: {
    name: 'เอกสารยืนยันตัวตน',
    subTitle: 'อัพโหลดเอกสารต่างๆ ให้ครบถ้วน โดยการแสกน หรือถ่ายภาพให้ชัดเจน และถ่ายภาพเซลฟี่เพื่อยืนยันตัวตน',
    idCard: 'สำเนาบัตรประชาชน พร้อมเซ็นรับรองสำเนาถูกต้อง',
    houseRegistration: 'สำเนาทะเบียนบ้าน หน้าแรกและหน้าที่มีชื่อผู้สมัครสมาชิกพรรค ',
    photo: 'รูปถ่ายครึ่งตัว สำหรับทำบัตรสมาชิก',
    nationalityChanged: 'สำเนาใบสำคัญการแปลงสัญชาติเป็นไทย',
    nameChanged: 'สำเนาใบสำคัญเปลี่ยนชื่อ',
    signature: 'รูปถ่ายลายเซ็นต์ผู้สมัคร',
    others: 'เอกสารอื่นๆ โปรดระบุ',
    requiredFileErrorMessage: 'ยังไม่ได้อัพโหลดเอกสาร',
    nameOther: 'ชื่อเอกสาร'
  }
}
