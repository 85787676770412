<template lang="pug">
  .row
    .col-6(:name="`${group}_appliedAt`")
      label {{ $t ('membership.form.receivedAndAppliedAt') }}
      date-selector(v-model="value.appliedAt")
      small.form-text.error.text-danger(v-show="!$v.appliedAt.required && $v.appliedAt.$dirty") {{ $t ('membership.form.receivedAndAppliedAt') }} ต้องไม่เป็นค่าว่าง
    .col-6
      label {{ $t ('membership.form.expiredAt') }}
      fake-input {{ expiredDateText }}
</template>
<script>
// import utils from '@/libs/utils'
import membershipHelper from '@/libs/membershipHelper'
import { mapFilters } from '@/map-filters'
export default {
  props: {
    value: {
      type: Object
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    },
    group: {
      default: ''
    }
  },
  computed: {
    expiredDateText () {
      if (this.value.appliedAt) {
        const expiredAt = membershipHelper.calculateMembershipExpireDate(this.value.appliedAt, this.value.membershipType)
        return this.value.membershipType === 'yearly' ? this.bdate(expiredAt) : 'ตลอดชีพ'
      }
      return ''
    }
  },
  methods: {
    ...mapFilters(['bdate'])
  }
}
</script>
