<template lang="pug">
  .row
    //- pre {{ model }}
    .col-12.form-group.mb-4
      div
        strong ประวัติการเป็นสมาชิกพรรคการเมือง
        div.d-flex.mt-3
          div
            b-form-radio.d-inline-block.mr-2(:value="false", v-model="memberHistoryStatus") ไม่เคย
            b-form-radio.d-inline-block.mr-2(:value="true", v-model="memberHistoryStatus") เคย
        div.d-flex.mt-3
          .d-flex.align-items-center
            label ชื่อพรรค
            party-input.ml-2(v-model="model.politicMembershipRecord", :disabled="!memberHistoryStatus", style="min-width: 400px;", :options="{ valueKey : 'name'}")
          .ml-2
            span และได้ลาออกจากการเป็นสมาชิกพรรคแล้ว
    .col-12.form-group
      .d-flex.align-items-center
        strong ประวัติการรับราชการ/ธุรกิจเอกชน
        .ml-3
          b-form-radio.d-inline-block.mr-2(:value="true", v-model="model.positionRecordCurrent") ตำแหน่งปัจจุบัน
          b-form-radio.d-inline-block.mr-2(:value="false", v-model="model.positionRecordCurrent") ตำแหน่งสุดท้าย
        .ml-1
          input.form-control(v-model="model.positionRecordName")
        .ml-2
          span สังกัด
        .ml-2
          input.form-control(v-model="model.positionRecordBelongTo")
    .col-12
      strong ประสบการณ์ด้านการเมือง
    .col-12.py-3(v-if="model && model.experiences")
      .form-group(v-show="model.experiences.length === 0")
        div.text-secondary.py-1 * ยังไม่มีประสบการณ์ด้านการเมือง
      .form-group(v-for="(experience, i) in model.experiences", :key="i")
        .d-flex.align-items-center
          .mr-3
            b-form-radio.d-inline.mr-2(v-model="experience.isWorking", :value="false") เคยสมัคร
            b-form-radio.d-inline.mr-2(v-model="experience.isWorking", :value="true") เคยเป็น
          .mr-3(style="width: 250px;")
            dropdown(v-model="experience.experienceType", :options="{ data: membershipExperienceType }")
          .mr-3(v-if="experience.experienceType", style="width: 250px;")
            input.form-control(v-model="experience.firstValue", :placeholder="getExperiencesInputFieldsName(experience.experienceType, 0)")
          .mr-3(v-if="countExperiencesInputFields(experience.experienceType) > 1" style="width: 250px;")
            input.form-control(v-model="experience.secondValue", :placeholder="getExperiencesInputFieldsName(experience.experienceType, 1)")
          .mr-3
            button.btn.btn-danger.btn-sm(@click.prevent="removeAt(i)")
              i.fas.fa-trash
        div
          small.form-text.error.text-danger(v-show="!$v.experiences.$each[i].experienceType.required && $v.experiences.$each[i].experienceType.$dirty") ประเภทประสบการณ์ด้านการเมือง ต้องไม่เป็นค่าว่าง
        div
          small.form-text.error.text-danger(v-show="!$v.experiences.$each[i].firstValue.required && $v.experiences.$each[i].firstValue.$dirty") ข้อมูลประสบการณ์ด้านการเมือง ต้องไม่เป็นค่าว่าง
    .col-12
      button.btn.btn-sm.btn-outline-primary(type="button", @click.prevent="createNewExperimence")
        i.fas.fa-plus
        span.ml-2 เพิ่ม ประสบการณ์ด้านการเมือง
</template>
<script>
// import utils from '@/libs/utils'

export default {
  props: {
    value: {
      type: Object
    },
    $v: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const localOptions = Object.assign({
      prefix: ''
    }, this.options)
    return {
      localOptions,
      memberHistoryStatus: false,
      membershipExperienceType: [
        { label: 'ส.ว.', value: 'senator', fields: ['จังหวัด'] },
        { label: 'ส.ส.', value: 'member', fields: ['จังหวัด'] },
        { label: 'สมาชิกสภาท้องถิ่น / ผู้บริหารท้องถิ่น', value: 'local', fields: ['ตำแหน่ง'] },
        { label: 'เคยตำรงตำแหน่งทางการเมือง', value: 'political', fields: ['ตำแหน่ง', 'เมื่อปี'] },
        { label: 'เคยเป็นกรรมกาบริหารพรรคการเมือง', value: 'party', fields: ['ชื่อพรรค', 'เมื่อปี'] }
      ]
    }
  },
  computed: {
    model () {
      return this.value
    }
  },
  watch: {
    memberHistoryStatus (val) {
      // set empty
      if (!this.memberHistoryStatus) {
        this.model.politicMembershipRecord = ''
      }
    }
  },
  mounted () {
    if (!this.model.experiences) {
      this.$set(this.model, 'experiences', [])
      // this.createNewExperimence()
    }
    if (this.model.politicMembershipRecord) {
      this.memberHistoryStatus = true
    }
  },
  methods: {
    createNewExperimence () {
      const experiences = this.model.experiences.slice(0)
      experiences.push({
        isWorking: false,
        experienceType: null,
        firstValue: '',
        secondValue: ''
      })
      this.$set(this.model, 'experiences', experiences)
    },
    countExperiencesInputFields (name) {
      const experimenceType = this.membershipExperienceType.find(type => type.value === name)
      return experimenceType ? experimenceType.fields.length : 1
    },
    getExperiencesInputFieldsName (name, fieldIndex) {
      const experimenceType = this.membershipExperienceType.find(type => type.value === name)
      return experimenceType ? experimenceType.fields[fieldIndex] : ''
    },
    removeAt (index) {
      const experiences = this.model.experiences.slice(0)
      experiences.splice(index, 1)
      this.$set(this.model, 'experiences', experiences)
    }
  }
}
</script>
