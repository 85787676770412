export default {
  path: '/waiting-member',
  component: () => import('@/layouts/default'), // dashboard
  children: [
    {
      name: 'waiting-member-index',
      path: '',
      component: () => import('@/views/waitingMember/Index')
    },
    {
      name: 'waiting-member-detail',
      path: ':id',
      component: () => import('@/views/waitingMember/Detail')
    }
  ]
}
