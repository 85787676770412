export default {
  name: 'สำนักงานและสาขาพรรค',
  form: {
    name: 'ชื่อ',
    subdistrictId: 'ที่อยู่',
    createdAt: 'สร้างเมื่อ',
    provinceId: 'จังหวัด',
    areaNo: 'เขต',
    isAreaBranch: 'เป็นสาขาพรรค'
  }
}
