<template lang="pug">
  b-modal(ref="modal", :title="title", @ok="save")
    form.register-form
      loading.py-5(v-if="submitting")
      .row(v-else)
        .col-12
          .form-group
            label {{ $t('party.form.name') }}
            input.form-control(data-test="party-name" type='text', v-model='model.name')
            small.form-text.text-danger(v-show="!$v.model.name.required && $v.model.name.$dirty") {{ $t('error.validation.required', { name: $t('party.form.name') }) }}
</template>
<script>
import { required } from 'vuelidate/lib/validators'
// import ProfileService from '@/services/profile'
export default {
  components: {
  },
  validations: {
    model: {
      name: { required }
    }
  },
  data () {
    return {
      submitting: false,
      model: {
        name: null
      }
    }
  },
  computed: {
    title () {
      let title = this.$t('party.name')
      if (!this.model.id) {
        title = `${this.$t('common.add')} ${title}`
      } else {
        title = `${this.$t('common.edit')} ${title}`
      }
      return title
    }
  },
  mounted () {
  },
  methods: {
    save (e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitting = true
        this.$services.party.save(this.model)
          .then((result) => {
            this.hide()
            this.$emit('save', result)
            setTimeout(() => {
              this.submitting = false
            }, 1000)
          })
          .catch(error => {
            this.submitting = false
            alert(error)
          })
      } else {
        this.$bvToast.toast('ยังกรอกข้อมูลไม่ถูกต้อง', {
          title: 'คำเตือน',
          variant: 'warning',
          solid: true
        })
      }
    },
    show (model) {
      if (model) {
        this.model = Object.assign({}, model)
      } else {
        this.model = {
          name: null
        }
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>
<style lang="scss">

</style>
