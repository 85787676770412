export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)

    if (params.search) {
      const q = params.search
      params.or = [`firstName||cont||${q}`, `lastName||cont||${q}`, `idCard||cont||${q}`]
      delete params.search
    }

    return http.get('blacklist', { params })
      .then(response => response.data)
  },
  find (id, query = {}) {
    const params = Object.assign({}, query)

    return http.get(`blacklist/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`blacklist`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`blacklist/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`blacklist/${id}`)
      .then(response => response.data)
  }
})
