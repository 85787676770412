export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)
    if (params.search) {
      params.or = [`member.firstName||cont||${query.search}`, `member.lastName||cont||${query.search}`]
      delete params.search
    }
    return http.get('member-level', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`member-level/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`member-level`, data)
      .then(response => response.data)
  },
  update (data) {
    return http.put(`member-level/${data.id}`, data)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`member-level/${id}`)
      .then(response => response.data)
  }
})
