export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)
    return http.get('election/area', { params })
      .then(response => response.data)
  },
  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`election/area/${id}`, { params })
      .then(response => response.data)
  },
  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },
  create (data) {
    return http.post(`election/area`, data)
      .then(response => response.data)
  },
  update (data) {
    const putData = this._clean(data)
    return http.put(`election/area/${putData.id}`, putData)
      .then(response => response.data)
  },
  delete (id) {
    return http.delete(`election/area/${id}`)
      .then(response => response.data)
  },
  _clean (data) {
    const result = Object.assign({}, data)
    if (result.subdistrict) {
      delete result.subdistrict
    }
    return result
  }
})
