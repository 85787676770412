export default {
  name: 'ผู้ใช้งานในระบบ',
  form: {
    email: 'อีเมล',
    name: 'ชื่อ - สกุล',
    firstName: 'ชื่อ',
    lastName: 'สกุล',
    role: 'หน้าที่',
    createdAt: 'สร้างเมื่อ'
  },
  role: {
    staff: 'เจ้าหน้าที่',
    supervisor: 'Supervisor',
    admin: 'Admin',
    accounting: 'การบัญชี',
    registrar: 'นายทะเบียน',
    area_agent_viewer: 'ผู้ชมตัวแทนเขต',
    branch_committee_viewer: 'ผู้ชมคณะกรรมการสาขาพรรค'
  }
}
