import { Buffer } from 'buffer'

export default (http) => ({
  getUserDocument (path, type = 'url') {
    const options = {
      params: {
        path,
        type
      }
    }
    if (type === 'file') {
      options.responseType = 'arraybuffer'
    }
    return http.get('online-channel-file/user-document', options)
      .then(response => {
        if (type === 'file') {
          return Buffer.from(response.data, 'binary').toString('base64')
        }
        return response.data
      })
  }
})
