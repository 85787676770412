export default (http) => ({
  findAll (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)

    if (params.search) {
      params.or = [`name||cont||${query.search}`]
      delete params.search
    }

    return http.get('paymentReceipt', { params })
      .then(response => response.data)
  },

  find (id, query) {
    const params = Object.assign({
    }, query)

    return http.get(`paymentReceipt/${id}`, { params })
      .then(response => response.data)
  },

  search (query) {
    const params = Object.assign({
      sort: 'id,DESC'
    }, query)
    return http.get('paymentReceipt/search', { params })
      .then(response => response.data)
  },

  save (data) {
    if (data.id) {
      return this.update(data)
    } else {
      return this.create(data)
    }
  },

  create (data) {
    return http.post(`paymentReceipt`, data)
      .then(response => response.data)
  },

  update (data) {
    return http.put(`paymentReceipt/${data.id}`, data)
      .then(response => response.data)
  },

  delete (id) {
    return http.delete(`paymentReceipt/${id}`)
      .then(response => response.data)
  },

  findPending (query) {
    const params = {
      join: ['membership', 'membership.member']
    }
    return this.findAll(Object.assign({}, params, query))
  },

  summaryYear (query) {
    return http.get('paymentReceipt/summary/year', { params: query })
      .then(response => response.data)
  },

  summaryMonth (query) {
    return http.get('paymentReceipt/summary/month', { params: query })
      .then(response => response.data)
  },

  summaryDay (query) {
    return http.get('paymentReceipt/summary/day', { params: query })
      .then(response => response.data)
  },

  reportDuplicate () {
    return http.get('paymentReceipt/report/duplicate')
      .then(response => response.data)
  },

  generateReceiptNumber (query) {
    return http.get('paymentReceipt/generate-receipt-number', { params: query })
      .then(response => response.data)
  },
  markPrint (data) {
    return http.post('paymentReceipt/mark-print', data)
      .then(response => response.data)
  },
  printReceipt (id) {
    return http.get(`paymentReceipt/print/${id}`, {
      responseType: 'blob',
      headers: {
        headers: { Accept: 'application/pdf' }
      }
    }).then(response => response.data)
  },
  printManyReceipts (params) {
    return http.get('paymentReceipt/prints', {
      responseType: 'blob',
      headers: { Accept: 'application/pdf' },
      params: params
    }).then(response => response.data)
  }
})
