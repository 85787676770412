
// import i18n from '@/lang/lang'
const STORAGE_KEY = 'membership'

let dataStorage = null
if (localStorage) {
  dataStorage = localStorage.getItem(STORAGE_KEY) != null
    ? JSON.parse(localStorage.getItem(STORAGE_KEY))
    : null
}
const util = {
  props: ['autofillData', 'autofill'],
  saveStateToStorage (state) {
    if (localStorage) {
      const storeData = {}
      this.props.forEach(key => {
        storeData[key] = state[key]
      })
      localStorage.setItem(STORAGE_KEY, JSON.stringify(storeData))
    }
  }
}

const state = Object.assign({
  autofillData: null,
  autofill: false
}, dataStorage)
const getters = {
  autofill: state => state.autofill
}
const mutations = {
  setAutofill (state, value) {
    state.autofill = value
    util.saveStateToStorage(state)
  },
  setAutofillData (state, data) {
    state.autofillData = data
    util.saveStateToStorage(state)
  }
}

const actions = {
  enableAutofillForm ({ commit }, value) {
    commit('setAutofill', value)
  },
  saveAutofillData ({ commit }, data) {
    commit('setAutofillData', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
