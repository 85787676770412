// import AuthService from '@/services/auth'
import Vue from 'vue'
import JWTDecode from 'jwt-decode'
import i18n from '@/langs'
const STORAGE_KEY = 'userInfo'
let loggedInUserStorage = null
if (localStorage) {
  loggedInUserStorage = localStorage.getItem(STORAGE_KEY) != null
    ? JSON.parse(localStorage.getItem(STORAGE_KEY))
    : null
}

const state = {
  loggedInUser: loggedInUserStorage,
  loading: false,
  error: null
}

const getters = {
  loggedInUser: state => state.loggedInUser,
  isAuthenticated: state => {
    if (state.loggedInUser) {
      try {
        var jwt = JWTDecode(state.loggedInUser.accessToken)
        let expireTime = (jwt.exp - 0) * 1000 // minus time to warn before
        if (jwt.sub && expireTime > Date.now()) {
          return true
        }
      } catch (err) {
        return false
      }
    }
    return false
  },
  loading: state => state.loading,
  error: state => state.error,
  fullname: state => {
    return state.loggedInUser ? state.loggedInUser.firstName + ' ' + state.loggedInUser.lastName : null
  },
  role: state => {
    return state.loggedInUser ? state.loggedInUser.role : null
  },
  id: state => {
    return state.loggedInUser ? state.loggedInUser.id : null
  }
}
const mutations = {
  setUser (state, data) {
    state.loggedInUser = data
    state.loading = false
    state.error = null
  },
  setLogout (state) {
    state.loggedInUser = null
    state.loading = false
    state.error = null
    // this.$router.go("/");
  },
  setLoading (state, data) {
    state.loading = data
    state.error = null
  },
  setError (state, data) {
    state.error = data
    state.loggedInUser = null
    state.loading = false
  },
  clearError (state) {
    state.error = null
  }
}

const actions = {
  login ({ commit }, data) {
    commit('clearError')
    commit('setLoading', true)
    return Vue.prototype.$services.auth.signin(data)
      .then(result => {
        const userDataDecoded = JWTDecode(result.accessToken)
        const userData = Object.assign({
          id: userDataDecoded.sub,
          firstName: userDataDecoded.firstName,
          lastName: userDataDecoded.lastName,
          role: userDataDecoded.role
        }, result)
        if (localStorage) {
          localStorage.setItem(STORAGE_KEY, JSON.stringify(userData))
        }
        // Vue.prototype.$services.auth.setAuthorizeHeader(result.accessToken)
        commit('setUser', userData)
        return userData
      })
      .catch(error => {
        if (localStorage) {
          localStorage.removeItem(STORAGE_KEY)
        }
        if (error.response && error.response.data && error.response.data.statusCode === 401) {
          commit('setError', i18n.t('module.auth.error.401'))
        } else {
          commit('setError', error.message)
        }
      })
  },
  signOut ({ commit }) {
    if (localStorage) {
      localStorage.removeItem(STORAGE_KEY)
    }
    // Vue.prototype.$services.auth.setAuthorizeHeader(null)
    commit('setLogout')
  },
  setAuthenHeader ({ commit }) {
    if (state.loggedInUser && state.loggedInUser.accessToken) {
      // Vue.prototype.$services.auth.setAuthorizeHeader(state.loggedInUser.accessToken)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
