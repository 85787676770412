export default (http) => ({
  findSubdistrict (id) {
    return http.get(`location/subdistrict/${id}`)
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findAllSubdistrict (query, q) {
    let params = Object.assign({
      per_page: 10
    }, query)
    if (q) {
      params.filter = 'name||starts||' + q
    }
    return http.get('location/subdistrict', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findAllDistrict (query, q) {
    let params = Object.assign({
      per_page: 10
    }, query)
    if (q) {
      params.filter = 'name||cont||' + q
    }
    return http.get('location/district', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findDistrict (id) {
    return http.get(`location/district/${id}`)
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findAllProvince (query, q) {
    let params = Object.assign({
      per_page: 100
    }, query)
    if (q) {
      params.filter = 'name||cont||' + q
    }
    return http.get('location/province', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findProvince (id) {
    return http.get(`location/province/${id}`)
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findAllZipcode (query, q) {
    let params = Object.assign({
      per_page: 10
    }, query)
    if (q) {
      params.filter = 'zipcode||eq||' + q
    }
    return http.get('location/subdistrict', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  },
  findSubdictByNameAndZipCode (name, zipcode) {
    const params = {
      filter: [
        'name||eq||' + name,
        'zipcode||eq||' + zipcode
      ]
    }
    return http.get('location/subdistrict', { params })
      .then(response => response.data)
      .then(result => {
        return result
      })
  }
})
