export default (http) => ({
  get (path, query) {
    const params = Object.assign({
    }, query)

    return http.get(`report/${path}`, { params })
      .then(response => {
        return response.data
      })
  }
})
