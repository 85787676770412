import store from '@/store'
export default (to, from, next) => {
  if (
    localStorage.getItem('userInfo') != null &&
    localStorage.getItem('userInfo').length > 0 &&
    store.getters['auth/isAuthenticated']
  ) {
    next()
  } else {
    localStorage.removeItem('userInfo')
    next('/auth/signin')
  }
}
